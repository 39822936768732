import { Balance } from "@/entities/balance";
import B from "bignumber.js";

export class Pool {
  public static NATIVE_ADDRESS_LENGTH = 64;
  public static ADDRESS_LENGTH = 56;
  public static FEE = new B("0.003");
  public isNative: boolean = false;

  constructor(
    public address: string,
    public name: string,
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public lpBalance: Balance,
    public aniBalance: Balance,
    public aniGovBalance: Balance,
    public aniFeeBalance: Balance,
    public aniShareBalance: Balance,
    public aniDebtBalance: Balance,
    public primaryAssetPrice: B,
    public secondaryAssetPrice: B,
    public isNew: boolean = false
  ) {
    this.isNative = this.address.length === Pool.NATIVE_ADDRESS_LENGTH;
  }

  public static createNull(isNative: boolean = false): Pool {
    const pool = new Pool(
      "",
      "",
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      Balance.createNull(),
      new B("0"),
      new B("0"),
      false
    );
    pool.isNative = isNative;

    return pool;
  }

  public isNull() {
    return this.address === "" && !this.isNew;
  }

  public calculateToAmount(amountFrom: B, atob: boolean): B {
    return new B(amountFrom).multipliedBy(
      atob ? this.primaryAssetPrice : this.secondaryAssetPrice
    );
  }

  public calculatePrimaryPositionBalance(lpAmount: B): Balance {
    let amount = lpAmount
      .multipliedBy(this.primaryBalance.amount)
      .dividedBy(this.lpBalance.limit);

    if (amount.isNaN()) {
      amount = new B(0);
    }

    return new Balance(this.primaryBalance.asset, amount, amount);
  }

  public calculateSecondaryPositionBalance(lpAmount: B): Balance {
    let amount = lpAmount
      .multipliedBy(this.secondaryBalance.amount)
      .dividedBy(this.lpBalance.limit);

    if (amount.isNaN()) {
      amount = new B(0);
    }

    return new Balance(this.secondaryBalance.asset, amount, amount);
  }
}
