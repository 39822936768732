
import BalanceCard from "@/components/BalanceCard.vue";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import USDC from "@/helpers/assets/USDC";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { BalanceCard },
  props: {
    pool: {
      type: Object as PropType<Pool>,
      required: true,
    },
    primaryAssetPrice: {
      type: Object as PropType<B>,
      required: true,
    },
    secondaryAssetPrice: {
      type: Object as PropType<B>,
      required: true,
    },
  },
  computed: {
    totalBalance(): Balance {
      const tlv = this.pool.primaryBalance.amount
        .multipliedBy(this.primaryAssetPrice)
        .plus(
          this.pool.secondaryBalance.amount.multipliedBy(
            this.secondaryAssetPrice
          )
        );

      return new Balance(USDC, tlv, tlv);
    },
  },
});
