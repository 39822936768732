<template>
  <form class="px-8 pt-6 pb-8 mb-4">
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="code">
        Asset Code
      </label>
      <input
        id="code"
        v-model="code"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="E.g. ARS"
        type="text"
      />
      <p v-if="!isCodeValid" class="text-red-500 text-xs italic">
        Please enter an asset code that is 1 to 12 characters long
      </p>
    </div>
    <div class="mb-6">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="address">
        Asset Address
      </label>
      <input
        id="address"
        v-model="address"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="E.g. GCYE7C77EB5AWAA25R5XMWNI2EDOKTTFTTPZKM2SR5DI4B4WFD52DARS"
        type="text"
      />
      <p v-if="!isAddressValid" class="text-red-500 text-xs italic">
        Please enter an address that is 56 characters long
      </p>
    </div>
    <div class="flex items-center justify-between">
      <Button
        class="cursor-pointer"
        type="secondary"
        @click.prevent="createAsset"
        >Done</Button
      >
      <Button type="tertiary" class="mx-10" @click.prevent="$emit('close')">
        Cancel
      </Button>
    </div>
  </form>
</template>

<script lang="ts">
import Button from "@/components/common/Button.vue";
import { Asset } from "@/entities/asset";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Button },
  emits: ["close", "create"],
  data() {
    return {
      code: "",
      address: "",
    };
  },
  computed: {
    isAddressValid(): boolean {
      const stellarAddressLength = 56;
      return this.address.length === stellarAddressLength;
    },
    isCodeValid(): boolean {
      const maxCodeLength = 12;
      const assetCodeLength = this.code.length;
      return assetCodeLength > 0 && assetCodeLength <= maxCodeLength;
    },
  },
  methods: {
    createAsset() {
      if (this.isAddressValid && this.isCodeValid) {
        this.$emit(
          "create",
          new Asset(false, this.code, this.address, "", true)
        );
        this.code = "";
        this.address = "";
      }
    },
  },
});
</script>

<style scoped></style>
