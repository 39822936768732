import { Asset } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import { Transaction } from "@/entities/transaction";
import USDC from "@/helpers/assets/USDC";
import XLM from "@/helpers/assets/XLM";
import { findAsset } from "@/helpers/assetsHelper";
import { mapNativePoolToEntity } from "@/mappers/poolMapper";
import { mapToEntity as mapNativeOpToEntity } from "@/mappers/transactions/nativeTransactionMapper";
import { mapToEntity as mapTxToEntity } from "@/mappers/transactions/transactionMapper";
import BigNumber from "bignumber.js";

export async function getTransactions(
  address: string,
  isNative: boolean = false
): Promise<Transaction[]> {
  const url = isNative
    ? `${process.env.VUE_APP_HORIZON_URL}/liquidity_pools/${address}/operations?order=desc&include_failed=false`
    : `${process.env.VUE_APP_HORIZON_URL}/accounts/${address}/transactions?order=desc&include_failed=false`;

  const transactionsResponse = await fetch(url);
  const transactions = await transactionsResponse.json();

  const mapper = isNative
    ? mapNativeOpToEntity.bind(
        null,
        process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!
      )
    : mapTxToEntity.bind(null, process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!);

  const filteredTransactions = transactions._embedded
    ? transactions._embedded.records
        .map(mapper)
        .filter((r: Transaction) => r !== undefined)
    : [];

  return filteredTransactions.map((t: Transaction) =>
    t.primaryBalance.asset.code < t.secondaryBalance.asset.code
      ? t
      : {
          ...t,
          primaryBalance: t.secondaryBalance,
          secondaryBalance: t.primaryBalance,
        }
  );
}

export async function getAssetBalanceForAddress(
  address: string,
  asset: Asset,
  isNativePoolShare: boolean = false
): Promise<Balance> {
  try {
    if (!address) {
      return Balance.createNull();
    }

    const accountResponse = await fetch(
      `${process.env.VUE_APP_HORIZON_URL}/accounts/${address}`
    );

    const account = await accountResponse.json();

    const balanceResponse = account.balances.find((b: any) => {
      if (isNativePoolShare && b.liquidity_pool_id === asset.issuer) {
        return true;
      }

      return (
        (b.asset_issuer === asset.issuer && b.asset_code === asset.code) ||
        (b.asset_type === "native" && asset.isNative)
      );
    });

    return new Balance(
      asset,
      new BigNumber(balanceResponse.balance as string),
      new BigNumber(balanceResponse.limit as string)
    );
  } catch (e) {
    const balance = Balance.createNull();
    balance.asset = asset;
    return balance;
  }
}

export async function getPriceInUSDC(sourceAsset: Asset): Promise<BigNumber> {
  try {
    const realAsset = findAsset(sourceAsset.code, sourceAsset.issuer);

    const assetType = realAsset.isNative
      ? "native"
      : realAsset.code.length > 4
      ? "credit_alphanum12"
      : "credit_alphanum4";

    const assetCode = realAsset.isNative ? "" : realAsset.code;

    const assetIssuer = realAsset.isNative ? "" : realAsset.productionIssuer;

    const pathResponse = await (
      await fetch(
        `https://horizon.stellar.org/paths/strict-send?source_amount=1&source_asset_type=${assetType}&source_asset_code=${assetCode}&source_asset_issuer=${assetIssuer}&destination_assets=USDC:${USDC.productionIssuer}`
      )
    ).json();

    return new BigNumber(
      pathResponse["_embedded"].records.pop().destination_amount || 0
    );
  } catch (e) {
    console.error(e);
    return new BigNumber(0);
  }
}

export async function getNativePools(): Promise<Pool[]> {
  const pools = await fetch(
    `${process.env.VUE_APP_HORIZON_URL}/liquidity_pools/?limit=100`
  );

  const records = (await pools.json())._embedded.records;
  return records.map(mapNativePoolToEntity);
}

export async function getNativePool(address: string): Promise<Pool> {
  const pool = await fetch(
    `${process.env.VUE_APP_HORIZON_URL}/liquidity_pools/${address}`
  );

  return mapNativePoolToEntity(await pool.json());
}

export async function getNativeLiquidityPoolByAssets(
  primaryAsset: Asset,
  secondaryAsset: Asset
): Promise<Pool> {
  const primaryReserve = primaryAsset.isEqual(XLM)
    ? "native"
    : `${primaryAsset.code}:${primaryAsset.issuer}`;

  const secondaryReserve = secondaryAsset.isEqual(XLM)
    ? "native"
    : `${secondaryAsset.code}:${secondaryAsset.issuer}`;

  const pool = await fetch(
    `${process.env.VUE_APP_HORIZON_URL}/liquidity_pools/?reserves=${primaryReserve},${secondaryReserve}`
  );

  return mapNativePoolToEntity((await pool.json())._embedded.records.pop());
}
