<template>
  <div class="flex justify-start">
    <div class="mr-2 flex-shrink-0" v-if="showIcon">
      <img :alt="asset.code" :src="assetLogo" class="h-8 w-8 block mt-1" />
    </div>
    <div class="text-left">
      <p class="font-bold">
        {{ customCode || asset.code || emptyCode || "Select" }}
      </p>
      <p class="text-xs">{{ shortenedAddress || "N/A" }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Asset as AssetEntity } from "@/entities/asset";
import { shortenAccount } from "@/helpers/accountHelper";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    asset: {
      type: Object as PropType<AssetEntity>,
      required: true,
    },
    customCode: {
      type: String,
      required: false,
    },
    emptyCode: {
      type: String,
      required: false,
    },
  },
  computed: {
    isNull(): boolean {
      return this.asset.isNull();
    },
    shortenedAddress(): string {
      return shortenAccount(this.asset.issuer);
    },
    assetLogo(): string {
      const code = this.asset.code.toLowerCase();
      try {
        return require(`../assets/logos/${code}.png`);
      } catch (e) {
        try {
          return require(`../assets/logos/${code}.svg`);
        } catch (e) {
          try {
            return require(`../assets/logos/${code}.jpg`);
          } catch (e) {
            return require(`../assets/logos/default.svg`);
          }
        }
      }
    },
  },
});
</script>

<style scoped></style>
