
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  emits: ["connect"],
  computed: {
    walletLogo(): string {
      return require(`../../assets/wallets/${this.name}.svg`);
    },
    capitalizedName(): string {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
  },
});
