<template>
  <div class="bg-gray-600 pt-3 text-white">
    <h3 class="text-lg font-bold text-center border-b border-gray-600 pb-3">
      Your stats
    </h3>
    <BalanceRow :balance="userLpBalance" custom-code="Pool Shares" />
    <div class="text-center">
      <img
        alt="down arrow"
        class="w-4 h-4 m-auto"
        src="../assets/down-arrow.svg"
      />
    </div>
    <BalanceRow :balance="userPrimaryPosition" />
    <BalanceRow :balance="userSecondaryPosition" />
    <div class="border-b border-gray-300"></div>
    <div>
      <div class="flex items-center justify-between p-2 text-gray-300">
        <div>
          <AssetComponent
            :asset="pool.lpBalance.asset"
            customCode="Your share of the pool"
          />
        </div>
        <p class="inline font-bold">% {{ userPoolPercentage }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AssetComponent from "@/components/Asset.vue";
import BalanceRow from "@/components/BalanceRow.vue";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { AssetComponent, BalanceRow },
  props: {
    pool: {
      type: Object as PropType<Pool>,
      required: true,
    },
    userPrimaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userSecondaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
  },
  computed: {
    userPrimaryPosition(): Balance {
      return this.pool.calculatePrimaryPositionBalance(
        this.userLpBalance.amount
      );
    },
    userSecondaryPosition(): Balance {
      return this.pool.calculateSecondaryPositionBalance(
        this.userLpBalance.amount
      );
    },
    userPoolPercentage(): string {
      let percentage = this.userLpBalance.amount
        .dividedBy(this.pool.lpBalance.limit)
        .multipliedBy(100);

      if (percentage.isNaN()) {
        percentage = new B(0);
      }

      return percentage.toFixed();
    },
  },
});
</script>
