<template>
  <div>
    <div class="flex cursor-pointer" @click="showModal = true">
      <div
        class="rounded-xl w-20 text-center bg-gray-200 text-gray-400 font-bold"
      >
        {{ slippage * 100 }}%
      </div>
      <img
        src="../../assets/cog.svg"
        alt="Set slippage"
        title="Set slippage"
        class="w-6 h-6"
      />
    </div>
    <VModal v-model="showModal">
      <div>
        <form @submit.prevent="">
          <strong class="font-bold text-gray-500">Slippage tolerance</strong>
          <div class="flex mt-2 cursor-pointer flex-wrap">
            <div
              @click="updateSlippage(0)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0%
            </div>
            <div
              @click="updateSlippage(0.001)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0.001)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0.1%
            </div>
            <div
              @click="updateSlippage(0.002)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0.002)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0.2%
            </div>
            <div
              @click="updateSlippage(0.003)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0.003)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0.3%
            </div>
            <div
              @click="updateSlippage(0.004)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0.004)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0.4%
            </div>
            <div
              @click="updateSlippage(0.005)"
              class="rounded-full w-20 text-center bg-gray-200 text-gray-400 font-bold py-0.5 mr-1 mt-1"
              :class="
                slippage.eq(0.005)
                  ? 'border-2 border-gray-400'
                  : 'border-2 border-gray-200'
              "
            >
              0.5%
            </div>
          </div>
        </form>
      </div>
    </VModal>
  </div>
</template>

<script lang="ts">
import VModal from "@/components/common/VModal.vue";
import B from "bignumber.js";
import { defineComponent } from "vue";

export default defineComponent({
  components: { VModal },
  emits: ["update"],
  data() {
    return {
      slippage: new B(0.001),
      showModal: false,
    };
  },
  methods: {
    updateSlippage(slippage: number): void {
      this.slippage = new B(slippage);
      this.showModal = false;
      this.$emit("update", this.slippage);
    },
  },
});
</script>
