<template>
  <div>
    <img
      alt="Loading icon"
      src="../../assets/loading.svg"
      v-if="type === 'light'"
      width="27"
    />
    <img
      alt="Loading icon"
      src="../../assets/loading-dark.svg"
      v-if="type === 'dark'"
      width="27"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "light",
    },
  },
});
</script>

<style scoped></style>
