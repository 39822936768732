export function shortenAccount(
  account?: string,
  charsLeft: number = 3,
  charsRight: number = 3
) {
  const STELLAR_ACCOUNT_LENGTH = 56;
  if (!account) return "";
  if (account.length < STELLAR_ACCOUNT_LENGTH) return account;

  return account
    .substr(0, charsLeft)
    .concat("...")
    .concat(account.substr(account.length - charsRight));
}
