import AppError from "@/errors/AppError";
import { isProduction, Networks } from "@/helpers/stellarHelper";
import freighterApi from "@stellar/freighter-api";
import { Server, TransactionBuilder } from "stellar-sdk";

export async function submitTx(
  xdr: string,
  stellarNetwork: Networks
): Promise<void> {
  const server = new Server(process.env.VUE_APP_HORIZON_URL!, {
    allowHttp: !isProduction(),
  });

  try {
    let freighterNetwork: "PUBLIC" | "TESTNET" | undefined;

    if (stellarNetwork === Networks.PUBNET) {
      freighterNetwork = "PUBLIC";
    } else if (stellarNetwork === Networks.TESTNET) {
      freighterNetwork = "TESTNET";
    }
    const signedXdr = await freighterApi.signTransaction(xdr, freighterNetwork);

    const tx = TransactionBuilder.fromXDR(
      signedXdr,
      process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!
    );

    await server.submitTransaction(tx);
  } catch (e) {
    console.error(e);
    throw new AppError(e.detail, e.type, e.title);
  }
}
