import { Balance } from "@/entities/balance";
import { AddIntent, Intent, RemoveIntent } from "@/entities/intent";
import StellarSdk, {
  Asset,
  Keypair,
  LiquidityPoolAsset,
  LiquidityPoolFeeV18,
  Operation,
  Server,
  TransactionBuilder,
} from "stellar-sdk";

export function getXdrFromIntent(intent: Intent): Promise<string> {
  switch (intent.opType) {
    case "create":
      return getCreateXdr(intent as AddIntent);
    case "add":
      return getAddXdr(intent as AddIntent);
    case "remove":
      return getRemoveXdr(intent as RemoveIntent);
    default:
      throw new Error(`Unknown op type ${intent.opType}`);
  }
}

async function getRemoveXdr(intent: RemoveIntent): Promise<string> {
  const server = new Server(process.env.VUE_APP_HORIZON_URL!);
  const sourceAccountKeypair = Keypair.fromPublicKey(intent.publicKey);

  const slippage = intent.slippageTolerance;
  const primaryAmount = intent.primaryBalance.amount;
  const secondaryAmount = intent.secondaryBalance.amount;

  const minAmountA = primaryAmount.minus(primaryAmount.multipliedBy(slippage));
  const minAmountB = secondaryAmount.minus(
    secondaryAmount.multipliedBy(slippage)
  );

  const account = await server.loadAccount(sourceAccountKeypair.publicKey());
  const transaction = new TransactionBuilder(account, {
    fee: StellarSdk.BASE_FEE,
    networkPassphrase: process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!,
  })
    .addOperation(
      Operation.liquidityPoolWithdraw({
        liquidityPoolId: intent.liquidityPool!,
        amount: intent.amount.toFixed(7),
        minAmountA: minAmountA.toFixed(7),
        minAmountB: minAmountB.toFixed(7),
      })
    )
    .setTimeout(60 * 60)
    .build();

  return transaction.toXDR();
}

async function getAddXdr(intent: AddIntent): Promise<string> {
  const server = new Server(process.env.VUE_APP_HORIZON_URL!);
  const sourceAccountKeypair = Keypair.fromPublicKey(intent.publicKey);
  const account = await server.loadAccount(sourceAccountKeypair.publicKey());
  const slippage = intent.slippageTolerance;

  const assetA = getStellarAssetFromBalance(intent.primaryBalance);
  const assetB = getStellarAssetFromBalance(intent.secondaryBalance);

  const primaryAmount = intent.primaryBalance.amount;
  const secondaryAmount = intent.secondaryBalance.amount;

  const exactPrice = primaryAmount.dividedBy(secondaryAmount);
  const minPrice = exactPrice
    .minus(exactPrice.multipliedBy(slippage))
    .toFixed(7);
  const maxPrice = exactPrice
    .plus(exactPrice.multipliedBy(slippage))
    .toFixed(7);

  const transaction = new TransactionBuilder(account, {
    fee: StellarSdk.BASE_FEE,
    networkPassphrase: process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!,
  })
    .addOperation(
      Operation.changeTrust({
        asset: getPoolAsset(assetA, assetB),
      })
    )
    .addOperation(
      Operation.liquidityPoolDeposit({
        liquidityPoolId: intent.liquidityPool!,
        maxAmountA: primaryAmount.toFixed(7),
        maxAmountB: secondaryAmount.toFixed(7),
        minPrice,
        maxPrice,
      })
    )
    .setTimeout(60 * 60)
    .build();

  return transaction.toXDR();
}

async function getCreateXdr(intent: AddIntent): Promise<string> {
  const server = new Server(process.env.VUE_APP_HORIZON_URL!);

  const sourceAccountKeypair = Keypair.fromPublicKey(intent.publicKey);
  const account = await server.loadAccount(sourceAccountKeypair.publicKey());

  const assetA = getStellarAssetFromBalance(intent.primaryBalance);
  const assetB = getStellarAssetFromBalance(intent.secondaryBalance);

  const transactionBuilder = new TransactionBuilder(account, {
    fee: StellarSdk.BASE_FEE,
    networkPassphrase: process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!,
  })
    .addOperation(
      Operation.changeTrust({
        asset: assetB,
      })
    )
    .addOperation(
      Operation.changeTrust({
        asset: getPoolAsset(assetA, assetB),
      })
    )
    .setTimeout(60);
  if (!assetA.isNative()) {
    transactionBuilder.addOperation(
      Operation.changeTrust({
        asset: assetA,
      })
    );
  }

  const transaction = transactionBuilder.build();
  return transaction.toXDR();
}

function getPoolAsset(assetA: Asset, assetB: Asset) {
  // -1 if assetA < assetB, 0 if assetA == assetB, 1 if assetA > assetB.
  const assetOrder = Asset.compare(assetA, assetB);
  // " Sort by: Native < AlphaNum4 < AlphaNum12, then by Code, then by Issuer, using lexicographic ordering."
  switch (assetOrder) {
    case -1: //// -1 if assetA < assetB
      return new LiquidityPoolAsset(
        assetA, // The first asset in the Pool, it must respect the rule assetA < assetB. See Asset.compare for more details on how assets are sorted.
        assetB, // The second asset in the Pool, it must respect the rule assetA < assetB. See Asset.compare for more details on how assets are sorted.
        LiquidityPoolFeeV18 //  The liquidity pool fee. For now the only fee supported is 30.
      );

    case 1: // 1 if assetA > assetB.
      return new LiquidityPoolAsset(
        assetB, // The first asset in the Pool, it must respect the rule assetA < assetB. See Asset.compare for more details on how assets are sorted.
        assetA, // The second asset in the Pool, it must respect the rule assetA < assetB. See Asset.compare for more details on how assets are sorted.
        LiquidityPoolFeeV18 //  The liquidity pool fee. For now the only fee supported is 30.
      );

    default:
      throw new Error("");
  }
}

function getStellarAssetFromBalance(balance: Balance) {
  return new Asset(
    balance.asset.code,
    balance.asset.issuer === "STELLAR" ? "" : balance.asset.issuer
  );
}
