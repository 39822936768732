<template>
  <form action="#" @onsubmit.prevent="">
    <div class="m-3">
      <p class="text-sm text-gray-500 my-3">
        Return pool shares to get your assets back
      </p>
      <AssetInput
        class="my-3"
        v-model.lazy="lpAssetAmount"
        :asset="pool.lpBalance.asset"
        :balance-amount="userLpBalance.amount"
        :disabled="true"
      />

      <div class="my-12 mx-10 flex justify-between">
        <span class="mr-5 text-gray-500 select-none">0%</span>
        <div class="flex-grow mt-2">
          <Slider
            v-model="percentageToRemove"
            :min="0"
            :max="100"
            :format="formatSlider"
            @update="handleSliderUpdate"
            show-tooltip="always"
            tooltip-position="bottom"
            :disabled="
              userLpBalance.amount.lte(0) || !$store.state.isWalletConnected
            "
          />
        </div>
        <span class="ml-5 text-gray-500 select-none">100%</span>
      </div>

      <div>
        <p class="text-sm text-gray-500">You receive (estimated)</p>
        <AssetInput
          class="my-3"
          :model-value="primaryAssetBalance.amount.toFixed(7)"
          :asset="pool.primaryBalance.asset"
          :balance-amount="pool.primaryBalance.amount"
          :disabled="true"
          :show-available="false"
        />
        <AssetInput
          :model-value="secondaryAssetBalance.amount.toFixed(7)"
          :asset="pool.secondaryBalance.asset"
          :balance-amount="pool.secondaryBalance.amount"
          :disabled="true"
          :show-available="false"
        />
      </div>
    </div>

    <div v-if="!pool.isNull() && !pool.primaryAssetPrice.isNaN()">
      <RelativeAssetPrice
        :primary-asset-price="pool.primaryAssetPrice"
        :primary-balance="pool.primaryBalance"
        :secondary-asset-price="pool.secondaryAssetPrice"
        :secondary-balance="pool.secondaryBalance"
      />
    </div>

    <div v-if="!pool.isNew" class="text-center my-4">
      <Button
        v-if="$store.state.isWalletConnected"
        href="#"
        type="primary"
        @click.prevent="
          () => {
            !isSubmitting && submit();
          }
        "
      >
        <SmallLoader v-if="isSubmitting" />
        <span v-if="!isSubmitting">Remove funds</span>
      </Button>
      <Button
        v-if="!$store.state.isWalletConnected"
        href="#"
        type="primary"
        @click.prevent="$store.dispatch('openWalletsModal')"
      >
        Connect wallet
      </Button>
    </div>
  </form>
</template>

<script lang="tsx">
import Button from "@/components/common/Button.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import AssetInput from "@/components/form/AssetInput.vue";
import RelativeAssetPrice from "@/components/form/v2/RelativeAssetPrice.vue";
import { Balance } from "@/entities/balance";
import { RemoveIntent } from "@/entities/intent";
import { Pool } from "@/entities/pool";
import Slider from "@vueform/slider";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    RelativeAssetPrice,
    SmallLoader,
    Button,
    AssetInput,
    Slider,
  },
  props: {
    pool: { type: Object as PropType<Pool>, required: true },
    isSubmitting: Boolean,
    slippageTolerance: {
      type: B,
      required: true,
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
  },
  emits: ["submit"],
  data() {
    return {
      lpAssetAmount: "0",
      percentageToRemove: 0,
    };
  },
  watch: {
    "pool.primaryBalance.amount": {
      handler() {
        this.lpAssetAmount = "0";
        this.percentageToRemove = 0;
      },
    },
  },
  computed: {
    primaryAssetBalance(): Balance {
      return this.pool.calculatePrimaryPositionBalance(
        new B(this.lpAssetAmount)
      );
    },
    secondaryAssetBalance(): Balance {
      return this.pool.calculateSecondaryPositionBalance(
        new B(this.lpAssetAmount)
      );
    },
  },
  methods: {
    formatSlider(val: string | number): string {
      return `${val}%`;
    },
    handleSliderUpdate(val: string | number): void {
      this.lpAssetAmount = this.userLpBalance.amount
        .multipliedBy(new B(val).dividedBy(100))
        .toFixed(7);
    },
    submit(): void {
      const lpAmountToRemove = new B(this.lpAssetAmount);
      if (lpAmountToRemove.lte(0)) {
        this.$toast("Please enter an amount", {
          styles: { background: "red" },
        });
        return;
      }

      this.$emit(
        "submit",
        new RemoveIntent(
          true,
          this.pool.address,
          lpAmountToRemove,
          this.primaryAssetBalance,
          this.secondaryAssetBalance,
          this.slippageTolerance,
          this.$store.state.pubKey,
          this.$store.state.privateKey
        )
      );
    },
  },
});
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
.slider-connect {
  background: #846ff4;
}
.slider-handle {
  background: #7451fb;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
}
.slider-handle:focus {
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
}
.slider-tooltip {
  color: black;
  border: 0;
  background: transparent;
}
</style>
