
import Wallet from "@/components/wallets/Wallet.vue";
import { Mutations } from "@/store/mutations";
import freighterApi from "@stellar/freighter-api";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["connected", "rejected"],
  methods: {
    connect(): void {
      const global: any = window;

      global.freighterApi = freighterApi;
      global.freighterApi
        .getPublicKey()
        .then((publicKey: string) => {
          this.$store.commit(Mutations.SET_PUB_KEY, publicKey);
          this.$store.commit(Mutations.SET_WALLET, "freighter");
          this.$emit("connected", publicKey);
        })
        .catch((error: any) => {
          this.$emit("rejected", error);
        });
    },
  },
  components: { Wallet },
});
