import { Asset } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import { Transaction } from "@/entities/transaction";
import ANI from "@/helpers/assets/ANI";
import BigNumber from "bignumber.js";
import StellarSdk from "stellar-sdk";

export function mapToEntity(
  networkPassphrase: string,
  stellarTx: any
): Transaction | undefined {
  const tx = StellarSdk.TransactionBuilder.fromXDR(
    stellarTx.envelope_xdr,
    networkPassphrase
  );

  const type = tx._memo.value()?.toString() as
    | "ADD"
    | "CREATE+ADD"
    | "SWAP"
    | "REMOVE";

  const txMap = {
    "CREATE+ADD": mapStellarCreateAddTx,
    ADD: mapStellarAddTx,
    SWAP: mapStellarSwapTx,
    REMOVE: mapStellarRemoveTx,
  };

  if (!Object.keys(txMap).includes(type)) {
    return;
  }

  const [primaryBalance, secondaryBalance, aniBalance] = txMap[type](tx);
  return new Transaction(
    stellarTx.id,
    type,
    primaryBalance,
    secondaryBalance,
    aniBalance,
    stellarTx.created_at
  );
}

function mapStellarCreateAddTx(tx: any) {
  const paymentOps = tx.operations.filter((o: any) => o.type === "payment");

  const primaryPaymentOp = paymentOps[5];
  const secondaryPaymentOp = paymentOps[6];

  return [
    buildBalanceFromPaymentOp(primaryPaymentOp),
    buildBalanceFromPaymentOp(secondaryPaymentOp),
    Balance.createNull(),
  ];
}

function mapStellarAddTx(tx: any) {
  const paymentOps = tx.operations.filter((o: any) => o.type === "payment");

  const primaryPaymentOp = paymentOps[0];
  const secondaryPaymentOp = paymentOps[1];

  return [
    buildBalanceFromPaymentOp(primaryPaymentOp),
    buildBalanceFromPaymentOp(secondaryPaymentOp),
    Balance.createNull(),
  ];
}

function mapStellarSwapTx(tx: any) {
  const paymentOps = tx.operations.filter((o: any) => o.type === "payment");
  const aniPayment = paymentOps.filter((o: any) => o.asset.code === ANI.code);
  const primaryPaymentOp = paymentOps[0];
  const secondaryPaymentOp = paymentOps[1];
  const aniPaymentOp = aniPayment.pop();

  return [
    buildBalanceFromPaymentOp(primaryPaymentOp),
    buildBalanceFromPaymentOp(secondaryPaymentOp),
    buildBalanceFromPaymentOp(aniPaymentOp),
  ];
}

function mapStellarRemoveTx(tx: any) {
  const paymentOps = tx.operations.filter((o: any) => o.type === "payment");

  const primaryPaymentOp = paymentOps[1];
  const secondaryPaymentOp = paymentOps[2];

  return [
    buildBalanceFromPaymentOp(primaryPaymentOp),
    buildBalanceFromPaymentOp(secondaryPaymentOp),
    Balance.createNull(),
  ];
}

function buildBalanceFromPaymentOp(paymentOp: any): Balance {
  if (!paymentOp) {
    return Balance.createNull();
  }

  return new Balance(
    new Asset(false, paymentOp.asset.code, paymentOp.asset.issuer),
    new BigNumber(paymentOp.amount),
    new BigNumber(paymentOp.amount)
  );
}
