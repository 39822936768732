<template>
  <div data-test="test-portal">
    <h1 class="text-2xl text-center text-yellow-400">
      Mint your own assets for test purposes. <br />The asset issuer is
      <FancyText class="mt-5">{{ issuerPublicKey }}</FancyText>
    </h1>
    <p class="text-center text-white mt-5">
      Need an account? Go to the
      <a
        class="underline"
        href="https://laboratory.stellar.org/#account-creator?network=test"
        target="_blank"
        >Stellar Laboratory</a
      >
    </p>
    <Card class="w-100 lg:w-1/2 center mx-auto p-5 mt-5">
      <form>
        <div class="mb-4">
          <div v-if="$store.state.isWalletConnected">
            <div class="text-center mb-4">
              <Button href="#" type="primary" @click.prevent="fundAllAssets">
                Get all assets
              </Button>
            </div>
            <hr />
            <p class="text-center">or get a specific asset</p>
            <hr />
          </div>
          <div class="flex flex-wrap">
            <AssetComponent
              v-for="a in availableAssets"
              :key="a.code.concat(a.issuer)"
              :asset="a"
              class="m-2 p-2 cursor-pointer"
              @click="() => setAssetCode(a.code)"
            />
          </div>
          <label
            class="block text-grey-darker text-sm font-bold mb-2"
            for="asset-code"
            >Asset code (you may also specify a custom asset code)</label
          >
          <input
            id="asset-code"
            v-model="assetCode"
            class="border rounded w-full py-2 px-3 text-grey-darker"
            maxlength="12"
            name="asset-code"
            placeholder="Enter the asset code (4 to 12 characters)"
            type="text"
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-grey-darker text-sm font-bold mb-2"
            for="amount"
          >
            Amount
          </label>
          <input
            id="amount"
            v-model="amount"
            class="border rounded w-full py-2 px-3 text-grey-darker"
            max="10000"
            min="1"
            name="amount"
            placeholder="1 to 1000"
            type="number"
          />
        </div>
        <div class="text-center">
          <SmallLoader v-if="loading" class="flex justify-center" type="dark" />
          <div v-if="!loading">
            <Button
              v-if="$store.state.isWalletConnected"
              class="mx-auto"
              href="#"
              type="secondary"
              @click.prevent="fundSingleAsset"
              >Submit
            </Button>
            <Button
              v-if="!$store.state.isWalletConnected"
              class="mx-auto"
              href="#"
              type="secondary"
              @click.prevent="connectWallet"
              >Sign In
            </Button>
          </div>
        </div>
      </form>
    </Card>
  </div>
</template>

<script lang="ts">
import AssetComponent from "@/components/Asset.vue";
import Button from "@/components/common/Button.vue";
import Card from "@/components/common/Card.vue";
import FancyText from "@/components/common/FancyText.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import ANI from "@/helpers/assets/ANI";
import XLM from "@/helpers/assets/XLM";
import defaultAssets from "@/helpers/assetsHelper";
import { Actions } from "@/store/actions";
import { Mutations } from "@/store/mutations";
import albedo from "@albedo-link/intent";
import StellarSdk, {
  Asset,
  Keypair,
  Operation,
  Server,
  TransactionBuilder,
} from "stellar-sdk";
import { defineComponent } from "vue";
export default defineComponent({
  components: { AssetComponent, FancyText, SmallLoader, Card, Button },
  data() {
    return {
      defaultAssets: [...defaultAssets] as AssetEntity[],
      loading: false,
      assetCode: "",
      amount: "",
      issuerPublicKey: process.env.VUE_APP_TEST_ASSET_ISSUER!,
      issuerPrivateKey: process.env.VUE_APP_TEST_ASSET_ISSUER_PRIVATE!,
    };
  },
  computed: {
    availableAssets(): AssetEntity[] {
      return this.defaultAssets.filter((a: AssetEntity) => {
        return a.code !== ANI.code && a.code !== XLM.code;
      });
    },
  },
  methods: {
    setAssetCode(code: string) {
      this.assetCode = code;
    },
    fundSingleAsset() {
      const asset = new Asset(this.assetCode, this.issuerPublicKey);
      this.submit([asset]);
    },
    fundAllAssets() {
      const assets = this.availableAssets.map((a: AssetEntity) => {
        return new Asset(a.code, a.issuer);
      });
      this.submit(assets);
    },
    async submit(assets: Asset[]): Promise<void> {
      try {
        this.loading = true;
        const pubKey = this.$store.state.pubKey;
        const issuerPublicKey = this.issuerPublicKey;
        const issuerPrivateKey = this.issuerPrivateKey;
        const issuerKeyPair = StellarSdk.Keypair.fromSecret(issuerPrivateKey);
        const server = new Server(`${process.env.VUE_APP_HORIZON_URL}/`, {
          allowHttp: true,
        });
        const fee = await server.fetchBaseFee();
        const userAccount = await server.loadAccount(pubKey);
        const transactionBuilder = new TransactionBuilder(userAccount, {
          fee: fee.toString(10),
          networkPassphrase: this.$store.state.stellarNetworkPassphrase,
        });
        assets.forEach((a: Asset) => {
          transactionBuilder
            .addOperation(
              Operation.changeTrust({
                asset: a,
                limit: "922337203685.4775807",
              })
            )
            .addOperation(
              Operation.payment({
                source: issuerPublicKey,
                destination: pubKey,
                amount: this.amount || String(1_000_000),
                asset: a,
              })
            );
        });
        const transaction = transactionBuilder.setTimeout(30).build();
        transaction.sign(issuerKeyPair);
        const xdr = transaction.toXDR();
        try {
          if (this.$store.state.wallet === "albedo") {
            await albedo.tx({
              xdr,
              network: this.$store.state.stellarNetwork,
              submit: true,
            });
          }
          if (this.$store.state.wallet === "rabet") {
            const global: any = window;
            const result = await global.rabet.sign(
              xdr,
              this.$store.state.stellarNetwork
            );
            const tx = TransactionBuilder.fromXDR(
              result.xdr,
              this.$store.state.stellarNetworkPassphrase
            );
            await server.submitTransaction(tx);
          }
          if (this.$store.state.wallet === "private-key") {
            transaction.sign(Keypair.fromSecret(this.$store.state.privateKey));
            await server.submitTransaction(transaction);
          }
          if (assets.length == 1) {
            const isDefaultAsset = this.defaultAssets.some(
              (a) => a.code == this.assetCode
            );
            if (!isDefaultAsset) {
              this.$store.commit(
                Mutations.ADD_CUSTOM_ASSET,
                new AssetEntity(
                  false,
                  this.assetCode,
                  issuerPublicKey,
                  "",
                  true
                )
              );
              this.$toast(
                "Success! The asset has been added to your list of custom assets. Go to the exchange to start using it."
              );
            } else {
              this.$toast(
                "Success! The asset has been added. Go to the exchange to start using it."
              );
            }
          } else {
            this.$toast("Success! All the assets have been added.");
          }
          this.loading = false;
        } catch (e) {
          this.$toast(e, { styles: { background: "red" } });
          this.loading = false;
        }
      } catch (e) {
        this.$toast(e, { styles: { background: "red" } });
        this.loading = false;
      }
    },
    connectWallet(): void {
      this.$store.dispatch(Actions.OPEN_WALLETS_MODAL);
    },
  },
});
</script>

<style scoped></style>
