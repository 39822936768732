
import Button from "@/components/common/Button.vue";
import VModal from "@/components/common/VModal.vue";
import Wallet from "@/components/wallets/Wallet.vue";
import { Mutations } from "@/store/mutations";
import { Keypair } from "stellar-sdk";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      error: false,
      showKeyModal: false,
      privateKey: "",
    };
  },
  emits: ["connected", "rejected"],
  methods: {
    connect(): void {
      try {
        const keyPair = Keypair.fromSecret(this.privateKey);
        const pubKey = keyPair.publicKey();
        this.$store.commit(Mutations.SET_PUB_KEY, pubKey);
        this.$store.commit(Mutations.SET_PRIVATE_KEY, this.privateKey);
        this.$store.commit(Mutations.SET_WALLET, "private-key");
        this.$emit("connected", pubKey);
        this.privateKey = "";
        this.error = false;
        this.showKeyModal = false;
      } catch (e) {
        this.error = true;
      }
    },
    closeModal() {
      this.privateKey = "";
      this.error = false;
      this.showKeyModal = false;
      this.$emit("rejected");
    },
  },
  components: { Wallet, VModal, Button },
});
