<template>
  <div :class="type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
.primary {
  @apply border-b-4 rounded-xl p-1 bg-gray-100 text-gray-800;
}

.secondary {
  @apply rounded-t-xl p-1 text-gray-300;
}
</style>
