import { Balance } from "@/entities/balance";
import B from "bignumber.js";

export class Intent {
  constructor(
    public opType: string,
    public isNative: boolean,
    public liquidityPool: string | null,
    public slippageTolerance: B,
    public publicKey: string,
    public privateKey?: string | null
  ) {}
}

export class CreateIntent extends Intent {
  constructor(
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public publicKey: string,
    public privateKey?: string | null
  ) {
    super("create", true, null, new B(0), publicKey, privateKey);
  }
}

export class AddIntent extends Intent {
  constructor(
    public isNative: boolean,
    public liquidityPool: string | null,
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public slippageTolerance: B,
    public publicKey: string,
    public privateKey?: string | null
  ) {
    super(
      "add",
      isNative,
      liquidityPool,
      slippageTolerance,
      publicKey,
      privateKey
    );
  }
}

export class SwapIntent extends Intent {
  constructor(
    public isNative: boolean,
    public liquidityPool: string | null,
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public slippageTolerance: B,
    public publicKey: string,
    public privateKey?: string | null
  ) {
    super(
      "swap",
      isNative,
      liquidityPool,
      slippageTolerance,
      publicKey,
      privateKey
    );
  }
}

export class RemoveIntent extends Intent {
  constructor(
    public isNative: boolean,
    public liquidityPool: string | null,
    public amount: B,
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public slippageTolerance: B,
    public publicKey: string,
    public privateKey?: string | null
  ) {
    super(
      "remove",
      isNative,
      liquidityPool,
      slippageTolerance,
      publicKey,
      privateKey
    );
  }
}
