
import BalanceRow from "@/components/BalanceRow.vue";
import Card from "@/components/common/Card.vue";
import { Balance } from "@/entities/balance";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { BalanceRow, Card },
  props: {
    balance: Object as PropType<Balance>,
    customCode: {
      type: String,
      required: false,
    },
    isFiat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
