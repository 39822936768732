
import AssetComponent from "@/components/Asset.vue";
import BalanceRow from "@/components/BalanceRow.vue";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { AssetComponent, BalanceRow },
  props: {
    pool: {
      type: Object as PropType<Pool>,
      required: true,
    },
    userPrimaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userSecondaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
  },
  computed: {
    userPrimaryPosition(): Balance {
      return this.pool.calculatePrimaryPositionBalance(
        this.userLpBalance.amount
      );
    },
    userSecondaryPosition(): Balance {
      return this.pool.calculateSecondaryPositionBalance(
        this.userLpBalance.amount
      );
    },
    userPoolPercentage(): string {
      let percentage = this.userLpBalance.amount
        .dividedBy(this.pool.lpBalance.limit)
        .multipliedBy(100);

      if (percentage.isNaN()) {
        percentage = new B(0);
      }

      return percentage.toFixed();
    },
  },
});
