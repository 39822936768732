<template>
  <div
    class="bg-gray-100 m-0.5 p-3 rounded-lg select-none"
    data-test="asset-input"
  >
    <div
      class="grid grid-cols-2 sm:grid-cols-3 cursor-pointer"
      @click="showModal = true"
    >
      <div class="col-auto order-first">
        <Asset :asset="asset" />
      </div>
      <div class="col-auto self-center sm:order-last">
        <img
          alt="Reverse"
          class="h-4 w-4 float-right"
          data-test="form-toggle"
          src="../../assets/reverse-arrow.svg"
          width="32"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <span
          class="bg-gray-100 border-0 text-gray-700 focus:outline-none focus:shadow-outline font-bold text-center sm:text-xl"
        >
          {{ balanceAmount }}
        </span>
      </div>
    </div>
    <VModal v-if="!isAssetLocked" v-model="showModal">
      <div
        v-for="a in assets"
        :key="a.code.concat(a.issuer)"
        @click="selectAsset(a)"
        class="flex justify-between cursor-pointer hover:bg-gray-300 p-1"
      >
        <Asset
          :asset="a"
          :data-testid="`modal-asset-${a.code.concat(a.issuer)}`"
          class="py-4"
          data-test="modal-asset"
        />
        <a
          href="#"
          class="mr-5 self-center underline hover:text-indigo-800"
          v-if="a.isCustom"
          @click.stop.prevent="removeAsset(a)"
        >
          Remove
        </a>
      </div>
      <template v-slot:footer>
        <Button
          class="cursor-pointer"
          type="secondary"
          @click="showCreateAssetModal = true"
        >
          Add custom asset
        </Button>
        <p
          v-if="!$store.getters.isProduction"
          class="text-red-500 text-sm w-full text-center"
        >
          Note: The assets in this list are testnet assets<br />
          you can get them through the
          <router-link class="underline" to="test-portal">
            test portal
          </router-link>
        </p>
      </template>
    </VModal>

    <VModal v-if="!isAssetLocked" v-model="showCreateAssetModal">
      <CustomAssetForm
        @close="showCreateAssetModal = false"
        @create="createAsset"
      />
    </VModal>
  </div>
</template>

<script lang="ts">
import Asset from "@/components/Asset.vue";
import Button from "@/components/common/Button.vue";
import VModal from "@/components/common/VModal.vue";
import CustomAssetForm from "@/components/form/CustomAssetForm.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import * as stellarHelper from "@/helpers/stellarHelper";
import { Mutations } from "@/store/mutations";
import { defineComponent } from "vue";

export default defineComponent({
  components: { CustomAssetForm, Button, VModal, Asset },
  props: {
    isAssetLocked: Boolean,
    disabled: Boolean,
    label: String,
    asset: AssetEntity,
    balanceAmount: String,
  },
  emits: ["selectAsset"],
  data() {
    return {
      showModal: false,
      showCreateAssetModal: false,
      isProduction: stellarHelper.isProduction(),
    };
  },
  computed: {
    assets(): AssetEntity[] {
      return this.$store.getters.assets;
    },
  },
  methods: {
    createAsset(asset: AssetEntity): void {
      this.$store.commit(Mutations.ADD_CUSTOM_ASSET, asset);
      this.showCreateAssetModal = false;
    },
    selectAsset(asset: AssetEntity): void {
      this.showModal = false;
      this.$emit("selectAsset", asset);
    },
    removeAsset(asset: AssetEntity): void {
      this.$nextTick(() =>
        this.$store.commit(Mutations.REMOVE_CUSTOM_ASSET, asset)
      );
    },
  },
});
</script>
