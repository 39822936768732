
import Asset from "@/components/Asset.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import { shortenAccount } from "@/helpers/accountHelper";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { Asset },
  props: {
    balance: Object as PropType<Balance>,
    customCode: {
      type: String,
      required: false,
    },
    isFiat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    asset(): AssetEntity {
      return this.balance?.asset || new AssetEntity(false, "", "");
    },
    shortenedAddress(): string {
      return shortenAccount(this.balance?.asset.issuer);
    },
    amount(): string {
      if (!this.balance) {
        return "0";
      }

      return this.isFiat
        ? "$".concat(this.balance.amount.toFormat(2))
        : this.balance.amount.toFormat(2);
    },
  },
});
