
import Button from "@/components/common/Button.vue";
import WalletModal from "@/components/wallets/WalletModal.vue";
import { Actions } from "@/store/actions";
import { Mutations } from "@/store/mutations";
import { defineComponent } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { mapGetters } from "vuex";

export default defineComponent({
  components: { WalletModal, Button },
  methods: {
    connectWallet(): void {
      this.$store.dispatch(Actions.OPEN_WALLETS_MODAL);
    },
    disconnectWallet(): void {
      this.$store.commit(Mutations.SET_PUB_KEY, "");
    },
  },
  computed: {
    ...mapGetters(["shortPubKey", "isProduction"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to: RouteLocationNormalized) {
        document.title = (to.meta.title as string) || "PlutoDAO";
      },
    },
  },
});
