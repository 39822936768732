import { Asset } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import XLM from "@/helpers/assets/XLM";
import { mapToEntity as mapAssetToEntity } from "@/mappers/assetMapper";
import B from "bignumber.js";

export function mapToEntity(apiResponse: any) {
  const { asset, amount, limit } = apiResponse;
  return new Balance(mapAssetToEntity(asset), new B(amount), new B(limit));
}

export function mapReserveToEntity(apiResponse: any): Balance {
  const amount = new B(apiResponse.amount);
  const reserveAsset: string = apiResponse.asset;
  let asset: Asset;

  if (reserveAsset === "native") {
    asset = XLM;
  } else {
    const [code, issuer] = reserveAsset.split(":");
    asset = new Asset(false, code, issuer);
  }

  return new Balance(asset, amount, amount);
}
