<template>
  <p class="fancy-text">
    <slot />
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped>
.fancy-text {
  background-image: linear-gradient(135deg, #846ff4 0%, #f17674 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
