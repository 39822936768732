export enum Networks {
  TESTNET = "testnet",
  PUBNET = "pubnet",
  LOCAL = "local",
}

export function toEnum(network: string): Networks {
  switch (network) {
    case Networks.LOCAL:
      return Networks.LOCAL;
    case Networks.TESTNET:
      return Networks.TESTNET;
    case Networks.PUBNET:
      return Networks.PUBNET;
    default:
      throw new Error("Invalid network");
  }
}

export const network = process.env.VUE_APP_STELLAR_NETWORK!;

export function isProduction(): boolean {
  return network === Networks.PUBNET;
}
