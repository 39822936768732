import { Balance } from "@/entities/balance";
import { Intent } from "@/entities/intent";
import ANI from "@/helpers/assets/ANI";
import { submitTx as submitAlbedoTx } from "@/services/albedo";
import { submitTx as submitFreighterTx } from "@/services/freighter";
import {
  getAssetBalanceForAddress,
  getNativePools as getStellarPools,
} from "@/services/horizon";
import {
  getIntentXdr,
  getLiquidityPools,
  submitTx as submitPrivateKeyTx,
} from "@/services/plutoDao";
import { submitTx as submitRabetTx } from "@/services/rabet";
import { IState } from "@/store/IState";
import { Mutations } from "@/store/mutations";
import { ActionContext } from "vuex";

export async function getPools({ commit }: ActionContext<IState, IState>) {
  commit(Mutations.SET_POOLS, await getLiquidityPools());
}

export async function getNativePools({
  commit,
}: ActionContext<IState, IState>) {
  commit(Mutations.SET_NATIVE_POOLS, await getStellarPools());
}

export interface ITransactionResult {
  successful: boolean;
  message?: string;
}

export async function transact(
  { commit, state }: ActionContext<IState, IState>,
  intent: Intent
): Promise<ITransactionResult> {
  if (state.wallet === "albedo") {
    try {
      const xdr = await getIntentXdr(intent);
      return (await submitAlbedoTx(xdr, state.stellarNetwork))
        .result as ITransactionResult;
    } catch (e) {
      return { successful: false, message: e.message };
    }
  } else if (state.wallet === "rabet") {
    try {
      const xdr = await getIntentXdr(intent);
      await submitRabetTx(xdr, state.stellarNetwork);
      return { successful: true };
    } catch (e) {
      return { successful: false, message: e.message };
    }
  } else if (state.wallet === "freighter") {
    try {
      const xdr = await getIntentXdr(intent);
      await submitFreighterTx(xdr, state.stellarNetwork);
      return { successful: true };
    } catch (e) {
      return { successful: false, message: e.message };
    }
  } else if (state.wallet === "private-key") {
    try {
      await submitPrivateKeyTx(intent);
      return { successful: true };
    } catch (e) {
      return { successful: false, message: e.message };
    }
  } else {
    throw Error("Unknown wallet");
  }
}

export async function openWalletsModal({
  commit,
}: ActionContext<IState, IState>) {
  commit(Mutations.SET_WALLETS_MODAL, true);
}

export async function closeWalletsModal({
  commit,
}: ActionContext<IState, IState>) {
  commit(Mutations.SET_WALLETS_MODAL, false);
}

export async function getIsFeedbackEnabled({
  state,
  commit,
}: ActionContext<IState, IState>): Promise<Balance> {
  try {
    const balance = await getAssetBalanceForAddress(state.pubKey, ANI);
    commit(Mutations.SET_IS_FEEDBACK_ENABLED, balance.limit.gt(0));
    return balance;
  } catch (e) {
    commit(Mutations.SET_IS_FEEDBACK_ENABLED, false);
    return Promise.resolve(Balance.createNull());
  }
}

export enum Actions {
  GET_POOLS = "getPools",
  GET_NATIVE_POOLS = "getNativePools",
  OPEN_WALLETS_MODAL = "openWalletsModal",
  CLOSE_WALLETS_MODAL = "closeWalletsModal",
  TRANSACT = "transact",
  GET_IS_FEEDBACK_ENABLED = "getIsFeedbackEnabled",
}

export default {
  [Actions.GET_POOLS]: getPools,
  [Actions.GET_NATIVE_POOLS]: getNativePools,
  [Actions.OPEN_WALLETS_MODAL]: openWalletsModal,
  [Actions.CLOSE_WALLETS_MODAL]: closeWalletsModal,
  [Actions.TRANSACT]: transact,
  [Actions.GET_IS_FEEDBACK_ENABLED]: getIsFeedbackEnabled,
};
