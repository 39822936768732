<template>
  <Card class="m-0.5">
    <div class="flex justify-end pt-4 pr-4" v-if="!pool.isNull()">
      <SlippageSelector @update="handleUpdateSlippage" />
    </div>
    <div class="text-center my-4">
      <Button
        href="#"
        :type="isAdd ? 'secondary' : 'tertiary'"
        @click.prevent="mode = 'add'"
        >Add funds</Button
      >
      <Button
        href="#"
        :type="isRemove ? 'secondary' : 'tertiary'"
        @click.prevent="mode = 'remove'"
        >Remove funds</Button
      >
    </div>
    <AddNativeForm
      v-if="isAdd"
      :is-submitting="isSubmitting"
      :pool="pool"
      :user-primary-asset-balance="userPrimaryAssetBalance"
      :user-secondary-asset-balance="userSecondaryAssetBalance"
      :slippage-tolerance="slippage"
      @submit="(...args) => $emit('submit', ...args)"
    />
    <RemoveNativeForm
      v-if="isRemove"
      :is-submitting="isSubmitting"
      :pool="pool"
      :user-lp-balance="userLpBalance"
      :slippage-tolerance="slippage"
      @submit="(...args) => $emit('submit', ...args)"
    />
  </Card>
</template>

<script lang="tsx">
import Button from "@/components/common/Button.vue";
import Card from "@/components/common/Card.vue";
import SlippageSelector from "@/components/form/SlippageSelector.vue";
import AddNativeForm from "@/components/form/v2/AddNativeForm.vue";
import RemoveNativeForm from "@/components/form/v2/RemoveNativeForm.vue";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    SlippageSelector,
    RemoveNativeForm,
    AddNativeForm,
    Button,
    Card,
  },
  props: {
    pool: { type: Object as PropType<Pool>, required: true },
    userPrimaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userSecondaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    isSubmitting: Boolean,
  },
  data() {
    return {
      mode: "add" as "add" | "remove",
      slippage: new B(0.001),
    };
  },
  computed: {
    isAdd(): boolean {
      return this.mode === "add";
    },
    isRemove(): boolean {
      return this.mode === "remove";
    },
  },
  methods: {
    handleUpdateSlippage(slippage: B) {
      this.slippage = slippage;
    },
  },
});
</script>
