import AppError from "@/errors/AppError";
import { isProduction, Networks } from "@/helpers/stellarHelper";
import albedo from "@albedo-link/intent";
import { Server, TransactionBuilder } from "stellar-sdk";

export function connect() {
  return albedo.publicKey({
    token: btoa(Math.random().toString() + Math.random().toString()),
  });
}

export async function submitTx(xdr: string, stellarNetwork: Networks) {
  const global: any = window;
  const server = new Server(process.env.VUE_APP_HORIZON_URL!, {
    allowHttp: !isProduction(),
  });

  try {
    const rabetNetwork =
      stellarNetwork === Networks.PUBNET ? "mainnet" : stellarNetwork;
    const result = await global.rabet.sign(xdr, rabetNetwork);

    const tx = TransactionBuilder.fromXDR(
      result.xdr,
      process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!
    );

    await server.submitTransaction(tx);
  } catch (e) {
    console.error(e);
    throw new AppError(e.detail, e.type, e.title);
  }
}
