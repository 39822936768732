
import AssetComponent from "@/components/Asset.vue";
import Button from "@/components/common/Button.vue";
import Card from "@/components/common/Card.vue";
import FancyText from "@/components/common/FancyText.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import ANI from "@/helpers/assets/ANI";
import XLM from "@/helpers/assets/XLM";
import defaultAssets from "@/helpers/assetsHelper";
import { Actions } from "@/store/actions";
import { Mutations } from "@/store/mutations";
import albedo from "@albedo-link/intent";
import StellarSdk, {
  Asset,
  Keypair,
  Operation,
  Server,
  TransactionBuilder,
} from "stellar-sdk";
import { defineComponent } from "vue";
export default defineComponent({
  components: { AssetComponent, FancyText, SmallLoader, Card, Button },
  data() {
    return {
      defaultAssets: [...defaultAssets] as AssetEntity[],
      loading: false,
      assetCode: "",
      amount: "",
      issuerPublicKey: process.env.VUE_APP_TEST_ASSET_ISSUER!,
      issuerPrivateKey: process.env.VUE_APP_TEST_ASSET_ISSUER_PRIVATE!,
    };
  },
  computed: {
    availableAssets(): AssetEntity[] {
      return this.defaultAssets.filter((a: AssetEntity) => {
        return a.code !== ANI.code && a.code !== XLM.code;
      });
    },
  },
  methods: {
    setAssetCode(code: string) {
      this.assetCode = code;
    },
    fundSingleAsset() {
      const asset = new Asset(this.assetCode, this.issuerPublicKey);
      this.submit([asset]);
    },
    fundAllAssets() {
      const assets = this.availableAssets.map((a: AssetEntity) => {
        return new Asset(a.code, a.issuer);
      });
      this.submit(assets);
    },
    async submit(assets: Asset[]): Promise<void> {
      try {
        this.loading = true;
        const pubKey = this.$store.state.pubKey;
        const issuerPublicKey = this.issuerPublicKey;
        const issuerPrivateKey = this.issuerPrivateKey;
        const issuerKeyPair = StellarSdk.Keypair.fromSecret(issuerPrivateKey);
        const server = new Server(`${process.env.VUE_APP_HORIZON_URL}/`, {
          allowHttp: true,
        });
        const fee = await server.fetchBaseFee();
        const userAccount = await server.loadAccount(pubKey);
        const transactionBuilder = new TransactionBuilder(userAccount, {
          fee: fee.toString(10),
          networkPassphrase: this.$store.state.stellarNetworkPassphrase,
        });
        assets.forEach((a: Asset) => {
          transactionBuilder
            .addOperation(
              Operation.changeTrust({
                asset: a,
                limit: "922337203685.4775807",
              })
            )
            .addOperation(
              Operation.payment({
                source: issuerPublicKey,
                destination: pubKey,
                amount: this.amount || String(1_000_000),
                asset: a,
              })
            );
        });
        const transaction = transactionBuilder.setTimeout(30).build();
        transaction.sign(issuerKeyPair);
        const xdr = transaction.toXDR();
        try {
          if (this.$store.state.wallet === "albedo") {
            await albedo.tx({
              xdr,
              network: this.$store.state.stellarNetwork,
              submit: true,
            });
          }
          if (this.$store.state.wallet === "rabet") {
            const global: any = window;
            const result = await global.rabet.sign(
              xdr,
              this.$store.state.stellarNetwork
            );
            const tx = TransactionBuilder.fromXDR(
              result.xdr,
              this.$store.state.stellarNetworkPassphrase
            );
            await server.submitTransaction(tx);
          }
          if (this.$store.state.wallet === "private-key") {
            transaction.sign(Keypair.fromSecret(this.$store.state.privateKey));
            await server.submitTransaction(transaction);
          }
          if (assets.length == 1) {
            const isDefaultAsset = this.defaultAssets.some(
              (a) => a.code == this.assetCode
            );
            if (!isDefaultAsset) {
              this.$store.commit(
                Mutations.ADD_CUSTOM_ASSET,
                new AssetEntity(
                  false,
                  this.assetCode,
                  issuerPublicKey,
                  "",
                  true
                )
              );
              this.$toast(
                "Success! The asset has been added to your list of custom assets. Go to the exchange to start using it."
              );
            } else {
              this.$toast(
                "Success! The asset has been added. Go to the exchange to start using it."
              );
            }
          } else {
            this.$toast("Success! All the assets have been added.");
          }
          this.loading = false;
        } catch (e) {
          this.$toast(e, { styles: { background: "red" } });
          this.loading = false;
        }
      } catch (e) {
        this.$toast(e, { styles: { background: "red" } });
        this.loading = false;
      }
    },
    connectWallet(): void {
      this.$store.dispatch(Actions.OPEN_WALLETS_MODAL);
    },
  },
});
