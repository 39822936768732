import {
  mapReserveToEntity,
  mapToEntity as mapBalanceToEntity,
} from "./balanceMapper";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";

export function mapToEntity(apiResponse: any) {
  const {
    address,
    name,
    primaryBalance,
    secondaryBalance,
    lpBalance,
    aniBalance,
    aniGovBalance,
    aniFeeBalance,
    aniShareBalance,
    aniDebtBalance,
    primaryAssetPrice,
    secondaryAssetPrice,
  } = apiResponse;

  return new Pool(
    address,
    name,
    mapBalanceToEntity(primaryBalance),
    mapBalanceToEntity(secondaryBalance),
    lpBalance ? mapBalanceToEntity(lpBalance) : Balance.createNull(),
    aniBalance ? mapBalanceToEntity(aniBalance) : Balance.createNull(),
    aniGovBalance ? mapBalanceToEntity(aniGovBalance) : Balance.createNull(),
    aniFeeBalance ? mapBalanceToEntity(aniFeeBalance) : Balance.createNull(),
    aniShareBalance
      ? mapBalanceToEntity(aniShareBalance)
      : Balance.createNull(),
    aniDebtBalance ? mapBalanceToEntity(aniDebtBalance) : Balance.createNull(),
    new B(primaryAssetPrice),
    new B(secondaryAssetPrice),
    false
  );
}

export function mapNativePoolToEntity(apiResponse: any) {
  const id = apiResponse.id;
  const primaryBalance = mapReserveToEntity(apiResponse.reserves[0]);
  const secondaryBalance = mapReserveToEntity(apiResponse.reserves[1]);
  const lpBalance = mapReserveToEntity({
    asset: "POOLSHARE:".concat(id),
    amount: new B(apiResponse.total_shares),
  });

  return new Pool(
    id,
    primaryBalance.asset.code.concat("/").concat(secondaryBalance.asset.code),
    mapBalanceToEntity(primaryBalance),
    mapBalanceToEntity(secondaryBalance),
    lpBalance,
    Balance.createNull(),
    Balance.createNull(),
    Balance.createNull(),
    Balance.createNull(),
    Balance.createNull(),
    secondaryBalance.amount.dividedBy(primaryBalance.amount) || new B("0"),
    primaryBalance.amount.dividedBy(secondaryBalance.amount) || new B("0"),
    false
  );
}
