export class Asset {
  constructor(
    public isNative: boolean,
    public code: string,
    public issuer: string,
    public productionIssuer: string = "",
    public isCustom: boolean = false
  ) {
    if (!productionIssuer) {
      this.productionIssuer = issuer;
    }
  }

  public static createNull(): Asset {
    return new Asset(false, "", "", "");
  }

  public isEqual(otherAsset: Asset) {
    return this.code === otherAsset.code && this.issuer === otherAsset.issuer;
  }

  public isNull() {
    return !this.isNative && this.code === "" && this.issuer === "";
  }
}
