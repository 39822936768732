import ARS from "./assets/ARS";
import BRL from "./assets/BRL";
import BTC from "./assets/BTC";
import { Asset } from "@/entities/asset";
import ETH from "@/helpers/assets/ETH";
import EURT from "@/helpers/assets/EURT";
import USDC from "@/helpers/assets/USDC";
import USDT from "@/helpers/assets/USDT";
import XLM from "@/helpers/assets/XLM";

export function findAsset(code: string, issuer: string): Asset {
  if (code === XLM.code && (!issuer || issuer === "STELLAR")) {
    return XLM;
  }

  return defaultAssets.find(
    (a: Asset) => a.code === code && a.issuer === issuer
  )!;
}

const defaultAssets: Asset[] = [XLM, USDC, USDT, ARS, BRL, BTC, ETH, EURT];

export default defaultAssets;
