
import VModal from "@/components/common/VModal.vue";
import B from "bignumber.js";
import { defineComponent } from "vue";

export default defineComponent({
  components: { VModal },
  emits: ["update"],
  data() {
    return {
      slippage: new B(0.001),
      showModal: false,
    };
  },
  methods: {
    updateSlippage(slippage: number): void {
      this.slippage = new B(slippage);
      this.showModal = false;
      this.$emit("update", this.slippage);
    },
  },
});
