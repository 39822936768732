import { Networks } from "@/helpers/stellarHelper";
import albedo from "@albedo-link/intent";

export function connect() {
  return albedo.publicKey({
    token: btoa(Math.random().toString() + Math.random().toString()),
  });
}

export function submitTx(xdr: string, stellarNetwork: Networks) {
  const albedoNetwork =
    stellarNetwork === Networks.PUBNET ? "public" : stellarNetwork;

  return albedo.tx({
    xdr,
    network: albedoNetwork,
    submit: true,
  });
}
