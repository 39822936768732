
import Button from "@/components/common/Button.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import AssetInput from "@/components/form/AssetInput.vue";
import RelativeAssetPrice from "@/components/form/v2/RelativeAssetPrice.vue";
import { Balance } from "@/entities/balance";
import { AddIntent } from "@/entities/intent";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { RelativeAssetPrice, SmallLoader, Button, AssetInput },
  props: {
    pool: { type: Object as PropType<Pool>, required: true },
    isSubmitting: Boolean,
    slippageTolerance: {
      type: B,
      required: true,
    },
    userPrimaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userSecondaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
  },
  emits: ["submit"],
  data() {
    return {
      atob: true,
      atobPrice: true,
      primaryAssetAmount: "0",
      secondaryAssetAmount: "0",
      recentUpdate: null as string | null,
      recentUpdateHandler: setTimeout(() => {}, 0),
    };
  },
  computed: {
    areInputsDisabled(): boolean {
      return this.pool.isNull() || !this.$store.state.isWalletConnected;
    },
  },
  watch: {
    "pool.address": {
      handler() {
        this.primaryAssetAmount = "0";
        this.secondaryAssetAmount = "0";
      },
    },
    "pool.primaryBalance.amount": {
      handler() {
        this.primaryAssetAmount = "0";
        this.secondaryAssetAmount = "0";
      },
    },
  },
  methods: {
    toggle(): void {
      this.atob = !this.atob;
    },
    debouncedUpdateAmounts(isPrimary: boolean): void {
      clearTimeout(this.recentUpdateHandler);
      if (this.pool.primaryAssetPrice.isNaN()) {
        return;
      }

      if (
        this.recentUpdate !== null &&
        this.recentUpdate !== (isPrimary ? "primary" : "secondary")
      ) {
        return;
      }

      this.recentUpdateHandler = setTimeout(() => {
        this.recentUpdate = isPrimary ? "primary" : "secondary";
        setTimeout(() => {
          this.recentUpdate = null;
        }, 300);

        const amount = this.pool.calculateToAmount(
          isPrimary
            ? new B(this.primaryAssetAmount)
            : new B(this.secondaryAssetAmount),
          isPrimary
        );

        const isBelowMinimum = amount.lt(0.0000001);
        if (isPrimary) {
          this.secondaryAssetAmount = isBelowMinimum
            ? "0"
            : amount.toString(10);
        } else {
          this.primaryAssetAmount = isBelowMinimum ? "0" : amount.toString(10);
        }

        this.handleOverMaxFunds();
      }, 300);
    },
    handleOverMaxFunds() {
      const totalPrimaryAmount = this.userPrimaryAssetBalance.amount;
      const totalSecondaryAmount = this.userSecondaryAssetBalance.amount;
      const primaryAmount = this.primaryAssetAmount;
      const secondaryAmount = this.secondaryAssetAmount;

      const exceedsPrimary = new B(primaryAmount).gt(totalPrimaryAmount);
      const exceedsSecondary = new B(secondaryAmount).gt(totalSecondaryAmount);

      if (exceedsPrimary || exceedsSecondary) {
        this.$nextTick(() => {
          const atob = !exceedsPrimary;
          const exceededAmount = exceedsPrimary
            ? secondaryAmount
            : primaryAmount;
          const amountFrom = new B(exceededAmount);
          let amountTo = this.pool.calculateToAmount(amountFrom, atob);

          const finalAmountTo = amountTo.lt(0.0000001)
            ? "0"
            : amountTo.toString(10);

          exceedsPrimary
            ? (this.primaryAssetAmount = finalAmountTo)
            : (this.secondaryAssetAmount = finalAmountTo);

          this.recentUpdate = exceedsPrimary ? "primary" : "secondary";
        });
      }
    },
    submit(): void {
      const primaryAmount = new B(this.primaryAssetAmount);
      const secondaryAmount = new B(this.secondaryAssetAmount);
      const primaryAsset = this.pool.primaryBalance.asset;
      const secondaryAsset = this.pool.secondaryBalance.asset;

      if (primaryAmount.lte(0) || secondaryAmount.lte(0)) {
        this.$toast("Please enter an amount", {
          styles: { background: "red" },
        });
        return;
      }

      this.$emit(
        "submit",
        new AddIntent(
          true,
          this.pool.address,
          new Balance(primaryAsset, primaryAmount, primaryAmount),
          new Balance(secondaryAsset, secondaryAmount, secondaryAmount),
          this.slippageTolerance,
          this.$store.state.pubKey,
          this.$store.state.privateKey
        )
      );
    },
  },
});
