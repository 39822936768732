
import { Asset as AssetEntity } from "@/entities/asset";
import { shortenAccount } from "@/helpers/accountHelper";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    asset: {
      type: Object as PropType<AssetEntity>,
      required: true,
    },
    customCode: {
      type: String,
      required: false,
    },
    emptyCode: {
      type: String,
      required: false,
    },
  },
  computed: {
    isNull(): boolean {
      return this.asset.isNull();
    },
    shortenedAddress(): string {
      return shortenAccount(this.asset.issuer);
    },
    assetLogo(): string {
      const code = this.asset.code.toLowerCase();
      try {
        return require(`../assets/logos/${code}.png`);
      } catch (e) {
        try {
          return require(`../assets/logos/${code}.svg`);
        } catch (e) {
          try {
            return require(`../assets/logos/${code}.jpg`);
          } catch (e) {
            return require(`../assets/logos/default.svg`);
          }
        }
      }
    },
  },
});
