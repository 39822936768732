<template>
  <div class="w-full">
    <div
      class="bg-gray-600 text-white shadow px-2 md:px-2 pt-4 pb-5 overflow-y-auto rounded-b"
    >
      <table class="w-full whitespace-nowrap table-fixed">
        <thead>
          <tr
            class="focus:outline-none h-16 w-full text-sm leading-none text-green-500 font-bold text-left"
            tabindex="0"
          >
            <th class="w-2/12">Op.</th>
            <th class="w-4/12 pl-2 text-center">Assets</th>
            <th class="w-2/12">Reward</th>
            <th class="pl-2 w-2/12">Date</th>
          </tr>
        </thead>
        <tbody class="w-full text-left">
          <tr
            v-for="transaction in transactions"
            v-bind:key="transaction.id"
            class="focus:outline-none h-20 text-sm leading-none border-b border-t border-gray-600"
            tabindex="0"
          >
            <td>
              <p class="text-sm font-medium leading-none">
                <a
                  :href="`https://stellar.expert/explorer/${stellarNetwork}/tx/${transaction.id}`"
                  target="_blank"
                >
                  {{ getTransactionType(transaction.type) }}
                </a>
              </p>
            </td>
            <td class="min-w-max">
              <div
                class="grid grid-cols-1 md:grid-cols-2 justify-items-stretch"
              >
                <div class="mx-0.5">
                  <p class="text-sm">
                    {{ transaction.primaryBalance.amount.toFixed(7) }}
                  </p>
                  <Asset
                    :asset="transaction.primaryBalance.asset"
                    :show-icon="false"
                  />
                </div>
                <div class="mx-0.5 my-2 md:my-0">
                  <p class="text-sm">
                    {{ transaction.secondaryBalance.amount.toFixed(7) }}
                  </p>
                  <Asset
                    :asset="transaction.secondaryBalance.asset"
                    :show-icon="false"
                  />
                </div>
              </div>
            </td>
            <td>
              <div>
                <p class="text-sm sm:col-span-6">
                  {{ transaction.aniBalance.amount.toFixed(7) }}
                </p>
                <Asset
                  :asset="transaction.aniBalance.asset"
                  v-if="!transaction.aniBalance.asset.isNull()"
                  :show-icon="false"
                />
              </div>
            </td>
            <td class="pl-2">
              <img
                src="../../assets/clock.svg"
                :alt="transaction.createdAt.toLocaleString()"
                :title="transaction.createdAt.toLocaleString()"
                class="clock w-4 h-4 sm:invisible"
              />
              <p class="font-medium invisible sm:visible">
                {{ transaction.createdAt.toLocaleString() }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import Asset from "@/components/Asset.vue";
import { Transaction } from "@/entities/transaction";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { Asset },
  props: {
    primaryAsset: Asset,
    secondaryAsset: Asset,
    transactions: {
      type: Object as PropType<Transaction[]>,
      required: true,
    },
  },
  computed: {
    stellarNetwork(): string {
      return process.env.VUE_APP_STELLAR_NETWORK!;
    },
  },
  methods: {
    getTransactionType(type: string): string {
      switch (type) {
        case "liquidity_pool_deposit":
          return "Deposit";
        case "liquidity_pool_withdraw":
          return "Withdraw";
        default:
          return "Swap";
      }
    },
  },
});
</script>

<style scoped>
.clock:hover:after {
  content: attr(title);
}
</style>
