
import Asset from "@/components/Asset.vue";
import Button from "@/components/common/Button.vue";
import VModal from "@/components/common/VModal.vue";
import CustomAssetForm from "@/components/form/CustomAssetForm.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import * as stellarHelper from "@/helpers/stellarHelper";
import { Mutations } from "@/store/mutations";
import { defineComponent } from "vue";

export default defineComponent({
  components: { CustomAssetForm, Button, VModal, Asset },
  props: {
    isAssetLocked: Boolean,
    disabled: Boolean,
    label: String,
    asset: AssetEntity,
    balanceAmount: String,
  },
  emits: ["selectAsset"],
  data() {
    return {
      showModal: false,
      showCreateAssetModal: false,
      isProduction: stellarHelper.isProduction(),
    };
  },
  computed: {
    assets(): AssetEntity[] {
      return this.$store.getters.assets;
    },
  },
  methods: {
    createAsset(asset: AssetEntity): void {
      this.$store.commit(Mutations.ADD_CUSTOM_ASSET, asset);
      this.showCreateAssetModal = false;
    },
    selectAsset(asset: AssetEntity): void {
      this.showModal = false;
      this.$emit("selectAsset", asset);
    },
    removeAsset(asset: AssetEntity): void {
      this.$nextTick(() =>
        this.$store.commit(Mutations.REMOVE_CUSTOM_ASSET, asset)
      );
    },
  },
});
