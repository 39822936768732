
import PrivateKey from "./PrivateKey.vue";
import Button from "@/components/common/Button.vue";
import VModal from "@/components/common/VModal.vue";
import Albedo from "@/components/wallets/Albedo.vue";
import Freighter from "@/components/wallets/Freighter.vue";
import Rabet from "@/components/wallets/Rabet.vue";
import { Actions } from "@/store/actions";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    showWalletModal(): boolean {
      return this.$store.state.showWalletsModal;
    },
  },
  methods: {
    handleConnected() {
      this.$store.dispatch(Actions.GET_IS_FEEDBACK_ENABLED);
      this.closeModal();
    },
    handleRejected(error: any) {
      this.$toast(error);
      this.closeModal();
    },
    closeModal() {
      this.$store.dispatch(Actions.CLOSE_WALLETS_MODAL);
    },
  },
  components: { Freighter, Rabet, PrivateKey, Albedo, VModal, Button },
});
