<template>
  <div id="background" />
  <div id="stars" />
  <WalletModal />
  <header class="grid grid-cols-12 md:p-4 m-2 lg:m-10">
    <router-link class="col-span-12 mb-5 md:mb-0 md:col-span-4" to="/">
      <div class="flex justify-center md:justify-start">
        <img alt="PlutoDao" src="./assets/logos/logo.svg" style="width: 50px" />
        <h2 class="text-white font-bold text-2xl self-center ml-2">PlutoDAO</h2>
      </div>
    </router-link>
    <div
      class="flex col-span-12 gap-x-2 justify-items-center justify-center md:col-span-8 md:justify-end"
    >
      <Button
        v-if="!$store.state.isWalletConnected"
        href="#"
        type="primary"
        @click.prevent="connectWallet"
      >
        Connect wallet
      </Button>
      <a
        v-if="$store.state.isWalletConnected"
        class="col-span-6 md:col-span-3 md:px-4 text-white underline"
        href="#"
        @click.prevent="disconnectWallet"
      >
        Logout - {{ shortPubKey }}
      </a>
    </div>
  </header>
  <main>
    <router-view></router-view>

    <div class="disclaimer my-4">
      <div class="text-white">
        <h1 class="text-center text-white text-2xl font-bold">
          Provide liquidity and earn interest with every trade, in real time
        </h1>
        <p class="text-center">
          Liquidity providers earn a 0.3% fee on all trades proportional to
          their share of the pool.
        </p>
      </div>
    </div>

    <p v-if="!isProduction" class="text-center text-white mt-10">
      PlutoDAO is currently operating in the testnet. You can get started with
      your own testnet assets or create some in the
      <Button
        href="/#/test-portal"
        type="primary"
        @click.prevent="$router.push('/test-portal')"
        >Test Portal
      </Button>
    </p>
  </main>
</template>

<script lang="ts">
import Button from "@/components/common/Button.vue";
import WalletModal from "@/components/wallets/WalletModal.vue";
import { Actions } from "@/store/actions";
import { Mutations } from "@/store/mutations";
import { defineComponent } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { mapGetters } from "vuex";

export default defineComponent({
  components: { WalletModal, Button },
  methods: {
    connectWallet(): void {
      this.$store.dispatch(Actions.OPEN_WALLETS_MODAL);
    },
    disconnectWallet(): void {
      this.$store.commit(Mutations.SET_PUB_KEY, "");
    },
  },
  computed: {
    ...mapGetters(["shortPubKey", "isProduction"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to: RouteLocationNormalized) {
        document.title = (to.meta.title as string) || "PlutoDAO";
      },
    },
  },
});
</script>

<style></style>
