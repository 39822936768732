
import Button from "@/components/common/Button.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import AssetInput from "@/components/form/AssetInput.vue";
import RelativeAssetPrice from "@/components/form/v2/RelativeAssetPrice.vue";
import { Balance } from "@/entities/balance";
import { RemoveIntent } from "@/entities/intent";
import { Pool } from "@/entities/pool";
import Slider from "@vueform/slider";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    RelativeAssetPrice,
    SmallLoader,
    Button,
    AssetInput,
    Slider,
  },
  props: {
    pool: { type: Object as PropType<Pool>, required: true },
    isSubmitting: Boolean,
    slippageTolerance: {
      type: B,
      required: true,
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
  },
  emits: ["submit"],
  data() {
    return {
      lpAssetAmount: "0",
      percentageToRemove: 0,
    };
  },
  watch: {
    "pool.primaryBalance.amount": {
      handler() {
        this.lpAssetAmount = "0";
        this.percentageToRemove = 0;
      },
    },
  },
  computed: {
    primaryAssetBalance(): Balance {
      return this.pool.calculatePrimaryPositionBalance(
        new B(this.lpAssetAmount)
      );
    },
    secondaryAssetBalance(): Balance {
      return this.pool.calculateSecondaryPositionBalance(
        new B(this.lpAssetAmount)
      );
    },
  },
  methods: {
    formatSlider(val: string | number): string {
      return `${val}%`;
    },
    handleSliderUpdate(val: string | number): void {
      this.lpAssetAmount = this.userLpBalance.amount
        .multipliedBy(new B(val).dividedBy(100))
        .toFixed(7);
    },
    submit(): void {
      const lpAmountToRemove = new B(this.lpAssetAmount);
      if (lpAmountToRemove.lte(0)) {
        this.$toast("Please enter an amount", {
          styles: { background: "red" },
        });
        return;
      }

      this.$emit(
        "submit",
        new RemoveIntent(
          true,
          this.pool.address,
          lpAmountToRemove,
          this.primaryAssetBalance,
          this.secondaryAssetBalance,
          this.slippageTolerance,
          this.$store.state.pubKey,
          this.$store.state.privateKey
        )
      );
    },
  },
});
