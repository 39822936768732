
import Asset from "@/components/Asset.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import B from "bignumber.js";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:modelValue", "setMaxAmount"],
  components: { Asset },
  props: {
    asset: {
      type: AssetEntity,
      required: true,
    },
    balanceAmount: { type: B, required: false, default: new B("0") },
    disabled: Boolean,
    showAvailable: {
      type: Boolean,
      default: true,
    },
    label: String,
    modelValue: String,
  },
  data() {
    return {
      mask: { mask: "#*.#######" },
    };
  },
  computed: {
    assets(): AssetEntity[] {
      return this.$store.getters.assets;
    },
  },
  methods: {
    validateInput(e: Event) {
      const target = e.target as HTMLInputElement;
      let value = new B(target.value);
      if (value.gt(this.balanceAmount)) {
        value = new B(this.balanceAmount);
        target.value = value.toString(10);
      }
      this.$emit("update:modelValue", target.value);
    },
    setMaxAmount(): void {
      if (!this.disabled) {
        const amount = new B(this.balanceAmount).toString(10);
        this.$emit("update:modelValue", amount);
        this.$emit("setMaxAmount", amount);
      }
    },
  },
});
