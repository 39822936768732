
import { Balance } from "@/entities/balance";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    primaryBalance: Object as PropType<Balance>,
    secondaryBalance: Object as PropType<Balance>,
    primaryAssetPrice: B,
    secondaryAssetPrice: B,
  },
  data() {
    return {
      atobPrice: true,
    };
  },
});
