<template>
  <Wallet
    name="private-key"
    @connect="showKeyModal = true"
    data-test="wallet"
    data-testid="wallet-private-key"
  />

  <VModal v-model="showKeyModal">
    <input
      v-model="privateKey"
      :class="error ? 'border-red-500' : ''"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
      placeholder="enter your private key"
      type="text"
      data-testid="wallet-private-key-input"
    />
    <p>Your private key is 56 digits long and starts with S</p>
    <p class="text-red-500">
      Even when PlutoDAO will never transmit your private key over the wire,
      <br />
      we strongly recommend that you use a wallet instead of using your private
      key directly.
    </p>
    <template v-slot:footer>
      <Button
        class="cursor-pointer text-center"
        @click="closeModal"
        type="tertiary"
      >
        Close
      </Button>
      <Button
        class="cursor-pointer"
        type="secondary"
        @click="connect"
        data-testid="wallet-private-key-connect"
      >
        Connect
      </Button>
    </template>
  </VModal>
</template>

<script lang="ts">
import Button from "@/components/common/Button.vue";
import VModal from "@/components/common/VModal.vue";
import Wallet from "@/components/wallets/Wallet.vue";
import { Mutations } from "@/store/mutations";
import { Keypair } from "stellar-sdk";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      error: false,
      showKeyModal: false,
      privateKey: "",
    };
  },
  emits: ["connected", "rejected"],
  methods: {
    connect(): void {
      try {
        const keyPair = Keypair.fromSecret(this.privateKey);
        const pubKey = keyPair.publicKey();
        this.$store.commit(Mutations.SET_PUB_KEY, pubKey);
        this.$store.commit(Mutations.SET_PRIVATE_KEY, this.privateKey);
        this.$store.commit(Mutations.SET_WALLET, "private-key");
        this.$emit("connected", pubKey);
        this.privateKey = "";
        this.error = false;
        this.showKeyModal = false;
      } catch (e) {
        this.error = true;
      }
    },
    closeModal() {
      this.privateKey = "";
      this.error = false;
      this.showKeyModal = false;
      this.$emit("rejected");
    },
  },
  components: { Wallet, VModal, Button },
});
</script>

<style scoped></style>
