import { Asset } from "@/entities/asset";
import B from "bignumber.js";

export class Balance {
  constructor(public asset: Asset, public amount: B, public limit: B) {}

  public static createNull(): Balance {
    return new Balance(Asset.createNull(), new B("0"), new B("0"));
  }
}
