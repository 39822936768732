import { Balance } from "@/entities/balance";

export class Transaction {
  constructor(
    public id: string,
    public type: string,
    public primaryBalance: Balance,
    public secondaryBalance: Balance,
    public aniBalance: Balance,
    public createdAt: Date
  ) {}
}
