import { Asset } from "@/entities/asset";
import { Pool } from "@/entities/pool";
import { shortenAccount } from "@/helpers/accountHelper";
import defaultAssets from "@/helpers/assetsHelper";
import { toEnum } from "@/helpers/stellarHelper";
import { IState } from "@/store/IState";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { createStore } from "vuex";

const baseState: IState = {
  pubKey: "",
  privateKey: "",
  environment: process.env.VUE_APP_ENVIRONMENT!.toString(),
  stellarNetwork: toEnum(process.env.VUE_APP_STELLAR_NETWORK!.toString()),
  stellarNetworkPassphrase: process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!.toString(),
  isWalletConnected: false,
  showWalletsModal: false,
  pool: Pool.createNull(),
  pools: [],
  nativePools: [],
  defaultAssets,
  customAssets: [],
  wallet: "",
  isFeedbackEnabled: false,
};

try {
  baseState.pubKey = sessionStorage.getItem("pubKey") || "";
  baseState.privateKey = sessionStorage.getItem("privateKey") || "";
  baseState.wallet = sessionStorage.getItem("wallet") || "";
  baseState.isWalletConnected = baseState.pubKey !== "";

  const customAssets =
    (JSON.parse(localStorage.getItem("customAssets")!) as Asset[]) || [];

  baseState.customAssets = customAssets.map(
    (a) => new Asset(a.isNative, a.code, a.issuer, "", true)
  );
  // eslint-disable-next-line no-empty
} catch (e) {}

export const store = createStore<IState>({
  state: baseState,
  mutations,
  actions,
  getters: {
    assets: (state) => state.defaultAssets.concat(state.customAssets),
    shortPubKey: (state) => shortenAccount(state.pubKey),
    isProduction: (state) => state.environment === "PRODUCTION",
  },
});
