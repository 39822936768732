import { App } from "vue";

const MAX_DECIMAL_NUMBERS = 7;

export interface INumberFormatPlugin {
  forStellar: (number: number | bigint) => string;
}

function truncateFractionAndFormat(parts: any, digits: any) {
  return parts
    .map(({ type, value }: any) => {
      if (type !== "fraction" || !value || value.length < digits) {
        return value;
      }

      let retVal = "";
      for (
        let idx = 0, counter = 0;
        idx < value.length && counter < digits;
        idx++
      ) {
        if (value[idx] !== "0") {
          counter++;
        }
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string: any, part: any) => string + part);
}

export default {
  install(app: App) {
    const numberModule: INumberFormatPlugin = {
      forStellar(number: number | bigint) {
        const formatter = new Intl.NumberFormat(undefined, {
          style: "decimal",
          minimumFractionDigits: 7,
          maximumFractionDigits: 7,
          useGrouping: true,
        });

        return truncateFractionAndFormat(
          formatter.formatToParts(number as number),
          MAX_DECIMAL_NUMBERS
        );
      },
    };

    app.config.globalProperties.$formatNumber = numberModule;
  },
};
