<template>
  <Wallet name="albedo" @connect="connect" />
</template>

<script lang="ts">
import Wallet from "@/components/wallets/Wallet.vue";
import { connect } from "@/services/albedo";
import { Mutations } from "@/store/mutations";
import { PublicKeyIntentResult } from "@albedo-link/intent";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["connected", "rejected"],
  methods: {
    connect(): void {
      connect()
        .then((r: PublicKeyIntentResult) => {
          this.$store.commit(Mutations.SET_PUB_KEY, r.pubkey);
          this.$store.commit(Mutations.SET_WALLET, "albedo");
          this.$emit("connected", r.pubkey);
        })
        .catch((r) => {
          this.$emit("rejected", r);
        });
    },
  },
  components: { Wallet },
});
</script>

<style scoped></style>
