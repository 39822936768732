<template>
  <div class="flex justify-end mr-4 mb-4">
    <p v-if="atobPrice" :title="primaryAssetPrice.toFormat()">
      1 {{ primaryBalance.asset.code }} = ~{{ primaryAssetPrice.toFormat(7) }}
      {{ secondaryBalance.asset.code }}
    </p>
    <p v-if="!atobPrice" :title="secondaryAssetPrice.toFormat()">
      1 {{ secondaryBalance.asset.code }} = ~{{
        secondaryAssetPrice.toFormat(7)
      }}
      {{ primaryBalance.asset.code }}
    </p>
    <div class="ml-2">
      <img
        alt="Reverse prices"
        class="w-4 h-4 cursor-pointer mt-1"
        src="../../../assets/reverse.svg"
        @click="atobPrice = !atobPrice"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Balance } from "@/entities/balance";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    primaryBalance: Object as PropType<Balance>,
    secondaryBalance: Object as PropType<Balance>,
    primaryAssetPrice: B,
    secondaryAssetPrice: B,
  },
  data() {
    return {
      atobPrice: true,
    };
  },
});
</script>

<style scoped></style>
