<template>
  <div
    class="bg-gray-200 p-3 rounded-lg select-none"
    data-test="asset-input"
    :class="disabled ? 'cursor-not-allowed' : ''"
  >
    <span class="w-100 block">{{ label }}</span>
    <div class="flex justify-between">
      <Asset
        :asset="asset"
        class="flex-shrink-0"
        data-test="asset-input-selector"
        empty-code="N/A"
      />
      <div class="text-right w-2/3">
        <div
          v-if="$store.state.isWalletConnected && showAvailable"
          class="flex w-11/12 float-right text-right justify-end text-xs"
        >
          <span class="truncate float-right">
            Available: {{ balanceAmount.toString() }}
          </span>
          <span>&nbsp;|&nbsp;</span>
          <a
            class="font-bold text-gray-800"
            href="#"
            @click.prevent="setMaxAmount"
            >MAX</a
          >
        </div>
        <input
          v-maska="mask"
          :disabled="disabled"
          :value="modelValue"
          class="bg-gray-200 border-0 text-gray-700 focus:outline-none focus:shadow-outline text-right text-2xl w-5/6"
          :class="disabled ? 'cursor-not-allowed' : ''"
          min="{0}"
          placeholder="-,--"
          type="text"
          @change="validateInput"
          @input="validateInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Asset from "@/components/Asset.vue";
import { Asset as AssetEntity } from "@/entities/asset";
import B from "bignumber.js";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:modelValue", "setMaxAmount"],
  components: { Asset },
  props: {
    asset: {
      type: AssetEntity,
      required: true,
    },
    balanceAmount: { type: B, required: false, default: new B("0") },
    disabled: Boolean,
    showAvailable: {
      type: Boolean,
      default: true,
    },
    label: String,
    modelValue: String,
  },
  data() {
    return {
      mask: { mask: "#*.#######" },
    };
  },
  computed: {
    assets(): AssetEntity[] {
      return this.$store.getters.assets;
    },
  },
  methods: {
    validateInput(e: Event) {
      const target = e.target as HTMLInputElement;
      let value = new B(target.value);
      if (value.gt(this.balanceAmount)) {
        value = new B(this.balanceAmount);
        target.value = value.toString(10);
      }
      this.$emit("update:modelValue", target.value);
    },
    setMaxAmount(): void {
      if (!this.disabled) {
        const amount = new B(this.balanceAmount).toString(10);
        this.$emit("update:modelValue", amount);
        this.$emit("setMaxAmount", amount);
      }
    },
  },
});
</script>
