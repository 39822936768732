<template>
  <a
    :class="type"
    :data-test="`button-${type}`"
    :href="href ?? null"
    class="inline-block px-9 rounded-full font-bold button"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: String,
    type: String,
  },
};
</script>

<style scoped>
.button {
  position: relative;
  z-index: 1;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  transition: all 250ms;
  cursor: pointer;
}
.primary {
  @apply bg-lightblue;
  color: #095a70;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.primary:focus {
  box-shadow: none;
}

.secondary {
  background: #6b647d;
  color: #f1eef5;
}

.tertiary {
  @apply bg-gray-200 text-gray-500;
  opacity: 0.5;
}

.tertiary:hover {
  opacity: 1;
  background: #6b647d;
  color: #f1eef5;
}

.quaternary {
  @apply bg-green-400 text-white;
}
</style>
