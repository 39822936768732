import { Asset } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import { Transaction } from "@/entities/transaction";
import XLM from "@/helpers/assets/XLM";
import B from "bignumber.js";

export function mapToEntity(
  networkPassphrase: string,
  stellarTx: any
): Transaction | undefined {
  if (stellarTx.type === "change_trust") {
    return;
  }

  let primaryReserve: any;
  let secondaryReserve: any;
  if (
    stellarTx.type === "path_payment_strict_receive" ||
    stellarTx.type === "path_payment_strict_send"
  ) {
    primaryReserve = {
      asset:
        stellarTx.asset_type === "native"
          ? "native"
          : `${stellarTx.asset_code}:${stellarTx.asset_issuer}`,
      amount: new B(stellarTx.amount),
    };

    secondaryReserve = {
      asset:
        stellarTx.source_asset_type === "native"
          ? "native"
          : `${stellarTx.source_asset_code}:${stellarTx.source_asset_code}`,
      amount: new B(stellarTx.source_amount),
    };
  } else {
    try {
      [primaryReserve, secondaryReserve] = stellarTx.reserves_received;
    } catch (e: any) {
      [primaryReserve, secondaryReserve] = stellarTx.reserves_deposited;
    }
  }

  return new Transaction(
    stellarTx.id,
    stellarTx.type,
    new Balance(
      createAssetFromReserve(primaryReserve),
      new B(primaryReserve.amount),
      new B(primaryReserve.amount)
    ),
    new Balance(
      createAssetFromReserve(secondaryReserve),
      new B(secondaryReserve.amount),
      new B(secondaryReserve.amount)
    ),
    Balance.createNull(),
    stellarTx.created_at
  );
}

function createAssetFromReserve(reserve: any): Asset {
  const isNative = reserve.asset === "native";

  let code = XLM.code;
  let issuer = XLM.issuer;

  if (!isNative) {
    [code, issuer] = reserve.asset.split(":");
  }

  return new Asset(isNative, code, issuer);
}
