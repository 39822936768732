
import Wallet from "@/components/wallets/Wallet.vue";
import { Mutations } from "@/store/mutations";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["connected", "rejected"],
  methods: {
    connect(): void {
      const global: any = window;

      global.rabet
        .connect()
        .then((result: any) => {
          this.$store.commit(Mutations.SET_PUB_KEY, result.publicKey);
          this.$store.commit(Mutations.SET_WALLET, "rabet");
          this.$emit("connected", result.publicKey);
        })
        .catch((error: any) => {
          this.$emit("rejected", error);
        });
    },
  },
  components: { Wallet },
});
