
import Asset from "@/components/Asset.vue";
import { Transaction } from "@/entities/transaction";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { Asset },
  props: {
    primaryAsset: Asset,
    secondaryAsset: Asset,
    transactions: {
      type: Object as PropType<Transaction[]>,
      required: true,
    },
  },
  computed: {
    stellarNetwork(): string {
      return process.env.VUE_APP_STELLAR_NETWORK!;
    },
  },
  methods: {
    getTransactionType(type: string): string {
      switch (type) {
        case "liquidity_pool_deposit":
          return "Deposit";
        case "liquidity_pool_withdraw":
          return "Withdraw";
        default:
          return "Swap";
      }
    },
  },
});
