
import Button from "@/components/common/Button.vue";
import Card from "@/components/common/Card.vue";
import SlippageSelector from "@/components/form/SlippageSelector.vue";
import AddNativeForm from "@/components/form/v2/AddNativeForm.vue";
import RemoveNativeForm from "@/components/form/v2/RemoveNativeForm.vue";
import { Balance } from "@/entities/balance";
import { Pool } from "@/entities/pool";
import B from "bignumber.js";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    SlippageSelector,
    RemoveNativeForm,
    AddNativeForm,
    Button,
    Card,
  },
  props: {
    pool: { type: Object as PropType<Pool>, required: true },
    userPrimaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userSecondaryAssetBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    userLpBalance: {
      type: Object as PropType<Balance>,
      required: true,
      default: Balance.createNull(),
    },
    isSubmitting: Boolean,
  },
  data() {
    return {
      mode: "add" as "add" | "remove",
      slippage: new B(0.001),
    };
  },
  computed: {
    isAdd(): boolean {
      return this.mode === "add";
    },
    isRemove(): boolean {
      return this.mode === "remove";
    },
  },
  methods: {
    handleUpdateSlippage(slippage: B) {
      this.slippage = slippage;
    },
  },
});
