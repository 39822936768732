import { Asset } from "@/entities/asset";
import { Pool } from "@/entities/pool";
import { IState } from "@/store/IState";

export function setPool(state: IState, pool: Pool) {
  state.pool = pool;
}

export function setPools(state: IState, pools: Pool[]) {
  state.pools = pools;
}

export function setNativePools(state: IState, pools: Pool[]) {
  state.nativePools = pools;
}

export function addCustomAsset(state: IState, asset: Asset) {
  state.customAssets.push(asset);
  localStorage.setItem("customAssets", JSON.stringify(state.customAssets));
}

export function removeCustomAsset(state: IState, asset: Asset) {
  const customAssets = [...state.customAssets];
  const i = customAssets.findIndex((a: Asset) => a.isEqual(asset));
  customAssets.splice(i, 1);
  state.customAssets = customAssets;
  localStorage.setItem("customAssets", JSON.stringify(customAssets));
}

export function setWalletsModal(state: IState, isOpen: boolean) {
  state.showWalletsModal = isOpen;
}

export function setPublicKey(state: IState, pubKey: string) {
  const STELLAR_ADDRESS_LENGTH = 56;
  state.pubKey = pubKey;
  sessionStorage.setItem("pubKey", pubKey);
  state.isWalletConnected = state.pubKey.length === STELLAR_ADDRESS_LENGTH;
}

export function setPrivateKey(state: IState, privateKey: string) {
  const STELLAR_ADDRESS_LENGTH = 56;
  state.privateKey = privateKey;
  sessionStorage.setItem("privateKey", privateKey);
  state.isWalletConnected = state.privateKey.length === STELLAR_ADDRESS_LENGTH;
}

export function setWallet(state: IState, wallet: string) {
  state.wallet = wallet;
  sessionStorage.setItem("wallet", wallet);
}

export function setIsFeedbackEnabled(
  state: IState,
  isFeedbackEnabled: boolean
) {
  state.isFeedbackEnabled = isFeedbackEnabled;
  localStorage.setItem(
    "isFeedbackEnabled",
    Number(isFeedbackEnabled).toString()
  );
}

export enum Mutations {
  SET_POOL = "setPool",
  SET_POOLS = "setPools",
  SET_NATIVE_POOLS = "setNativePools",
  ADD_CUSTOM_ASSET = "addCustomAsset",
  REMOVE_CUSTOM_ASSET = "removeCustomAsset",
  SET_WALLETS_MODAL = "setWalletsModal",
  SET_PUB_KEY = "setPubKey",
  SET_PRIVATE_KEY = "setPrivateKey",
  SET_WALLET = "setWallet",
  SET_IS_FEEDBACK_ENABLED = "setIsFeedbackEnabled",
}

export default {
  [Mutations.SET_POOL]: setPool,
  [Mutations.SET_POOLS]: setPools,
  [Mutations.SET_NATIVE_POOLS]: setNativePools,
  [Mutations.ADD_CUSTOM_ASSET]: addCustomAsset,
  [Mutations.REMOVE_CUSTOM_ASSET]: removeCustomAsset,
  [Mutations.SET_WALLETS_MODAL]: setWalletsModal,
  [Mutations.SET_PUB_KEY]: setPublicKey,
  [Mutations.SET_PRIVATE_KEY]: setPrivateKey,
  [Mutations.SET_WALLET]: setWallet,
  [Mutations.SET_IS_FEEDBACK_ENABLED]: setIsFeedbackEnabled,
};
