<template>
  <a href="#" @click.prevent="this.$emit('connect', name)" class="my-4">
    <img
      :src="walletLogo"
      width="25"
      height="25"
      :alt="capitalizedName.concat(' wallet')"
      class="inline-grid mr-5"
    />
    <span>Connect using {{ capitalizedName }}</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  emits: ["connect"],
  computed: {
    walletLogo(): string {
      return require(`../../assets/wallets/${this.name}.svg`);
    },
    capitalizedName(): string {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
  },
});
</script>

<style scoped></style>
