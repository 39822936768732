
import PoolStats from "@/components/PoolStats.vue";
import UserStats from "@/components/UserStats.vue";
import Button from "@/components/common/Button.vue";
import SmallLoader from "@/components/common/SmallLoader.vue";
import VModal from "@/components/common/VModal.vue";
import AssetSelector from "@/components/form/AssetSelector.vue";
import NativePoolForm from "@/components/form/v2/NativePoolForm.vue";
import NativeTransactions from "@/components/transactions/NativeTransactions.vue";
import { Asset as AssetEntity, Asset } from "@/entities/asset";
import { Balance } from "@/entities/balance";
import { CreateIntent, Intent } from "@/entities/intent";
import { Pool } from "@/entities/pool";
import { Transaction } from "@/entities/transaction";
import {
  getAssetBalanceForAddress,
  getNativeLiquidityPoolByAssets,
  getPriceInUSDC,
  getTransactions,
} from "@/services/horizon";
import { Actions, ITransactionResult } from "@/store/actions";
import { Mutations } from "@/store/mutations";
import B from "bignumber.js";
import { defineComponent } from "vue";
import { MutationPayload } from "vuex";
export default defineComponent({
  name: "Native",
  components: {
    AssetSelector,
    UserStats,
    PoolStats,
    NativeTransactions,
    SmallLoader,
    Button,
    VModal,
    NativePoolForm,
  },
  data() {
    return {
      primaryAsset: Asset.createNull(),
      secondaryAsset: Asset.createNull(),
      userPrimaryAssetBalance: Balance.createNull(),
      userSecondaryAssetBalance: Balance.createNull(),
      userLpBalance: Balance.createNull(),
      primaryAssetPrice: new B(0),
      secondaryAssetPrice: new B(0),
      pool: Pool.createNull(true),
      transactions: [] as Transaction[],
      isLoading: false,
      isSubmitting: false,
      isCreatingPool: false,
      showModal: false,
    };
  },
  created(): void {
    this.$store.subscribe((mutation: MutationPayload): void => {
      if (
        mutation.type === Mutations.SET_PUB_KEY &&
        mutation.payload &&
        !this.pool.isNull()
      ) {
        this.loadBalances();
      }
      if (mutation.type === Mutations.SET_PUB_KEY && !mutation.payload) {
        this.userPrimaryAssetBalance = Balance.createNull();
        this.userSecondaryAssetBalance = Balance.createNull();
        this.userLpBalance = Balance.createNull();
      }
    });
  },
  computed: {
    primaryBalance(): Balance {
      if (this.pool.isNull()) {
        return this.pool.primaryBalance as Balance;
      }
      return (this.pool.primaryBalance.asset.isEqual(this.primaryAsset)
        ? this.pool.primaryBalance
        : this.pool.secondaryBalance) as Balance;
    },
    secondaryBalance(): Balance {
      if (this.pool.isNull()) {
        return this.pool.secondaryBalance as Balance;
      }
      return (this.pool.secondaryBalance.asset.isEqual(this.secondaryAsset)
        ? this.pool.secondaryBalance
        : this.pool.primaryBalance) as Balance;
    },
  },
  methods: {
    async loadBalances(): Promise<void> {
      const pk = this.$store.state.pubKey;
      const [
        primaryBalance,
        secondaryBalance,
        lpBalance,
        primaryPrice,
        secondaryPrice,
      ] = await Promise.all([
        getAssetBalanceForAddress(pk, this.pool.primaryBalance.asset),
        getAssetBalanceForAddress(pk, this.pool.secondaryBalance.asset),
        getAssetBalanceForAddress(pk, this.pool.lpBalance.asset, true),
        getPriceInUSDC(this.pool.primaryBalance.asset),
        getPriceInUSDC(this.pool.secondaryBalance.asset),
      ]);
      this.userPrimaryAssetBalance = primaryBalance;
      this.userSecondaryAssetBalance = secondaryBalance;
      this.userLpBalance = lpBalance;
      this.primaryAssetPrice = primaryPrice;
      this.secondaryAssetPrice = secondaryPrice;
    },
    async handlePrimaryAssetSelected(asset: AssetEntity) {
      this.primaryAsset = asset;
      if (this.primaryAsset.isEqual(this.secondaryAsset)) {
        this.primaryAsset = Asset.createNull();
        this.pool = Pool.createNull(true);
        return false;
      }
      await this.getPool();
    },
    async handleSecondaryAssetSelected(asset: AssetEntity) {
      this.secondaryAsset = asset;
      if (this.primaryAsset.isEqual(this.secondaryAsset)) {
        this.secondaryAsset = Asset.createNull();
        this.pool = Pool.createNull(true);
        return false;
      }
      await this.getPool();
    },
    async handleSubmit(intent: Intent): Promise<void> {
      try {
        this.isSubmitting = true;
        const result = await this.$store.dispatch(Actions.TRANSACT, intent);
        this.$toast(
          result.successful
            ? "Success"
            : `Transaction failed ${result.message}`,
          !result.successful ? { styles: { background: "red" } } : {}
        );
        if (result.successful) {
          await Promise.all([this.loadBalances(), this.getPool()]);
        }
      } catch (e) {
        console.error(e);
        this.$toast(e.message, { styles: { background: "red" } });
      } finally {
        this.isSubmitting = false;
      }
    },
    closeModal(): void {
      this.showModal = false;
      this.primaryAsset = Asset.createNull();
      this.secondaryAsset = Asset.createNull();
      this.pool = Pool.createNull(true);
    },
    async createPool(): Promise<void> {
      try {
        this.isCreatingPool = true;
        const result = (await this.$store.dispatch(
          Actions.TRANSACT,
          new CreateIntent(
            this.pool.primaryBalance as Balance,
            this.pool.secondaryBalance as Balance,
            this.$store.state.pubKey,
            this.$store.state.privateKey
          )
        )) as ITransactionResult;
        this.$toast(
          result.successful
            ? `Pool ${this.primaryAsset.code}/${this.secondaryAsset.code} created.`
            : "Pool could not be created"
        );
        if (result.successful) {
          await this.getPool();
          this.showModal = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isCreatingPool = false;
      }
    },
    async getPool(): Promise<void> {
      const primaryAsset = this.primaryAsset;
      const secondaryAsset = this.secondaryAsset;
      this.pool.primaryBalance.amount = new B("0");
      this.pool.secondaryBalance.amount = new B("0");
      if (primaryAsset.isNull() || secondaryAsset.isNull()) {
        return;
      }
      this.isLoading = true;
      try {
        this.pool = await getNativeLiquidityPoolByAssets(
          primaryAsset,
          secondaryAsset
        );
        const [transactions] = await Promise.all([
          await getTransactions(this.pool.address, true),
          this.loadBalances(),
        ]);
        this.transactions = transactions;
      } catch {
        this.pool.primaryBalance.asset = this.primaryAsset;
        this.pool.secondaryBalance.asset = this.secondaryAsset;
        this.pool.isNew = true;
        this.showModal = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
