
import Button from "@/components/common/Button.vue";
import { Asset } from "@/entities/asset";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Button },
  emits: ["close", "create"],
  data() {
    return {
      code: "",
      address: "",
    };
  },
  computed: {
    isAddressValid(): boolean {
      const stellarAddressLength = 56;
      return this.address.length === stellarAddressLength;
    },
    isCodeValid(): boolean {
      const maxCodeLength = 12;
      const assetCodeLength = this.code.length;
      return assetCodeLength > 0 && assetCodeLength <= maxCodeLength;
    },
  },
  methods: {
    createAsset() {
      if (this.isAddressValid && this.isCodeValid) {
        this.$emit(
          "create",
          new Asset(false, this.code, this.address, "", true)
        );
        this.code = "";
        this.address = "";
      }
    },
  },
});
