import { Asset } from "@/entities/asset";
import { Intent } from "@/entities/intent";
import { Pool } from "@/entities/pool";
import AppError from "@/errors/AppError";
import { mapToEntity } from "@/mappers/poolMapper";
import { getXdrFromIntent } from "@/services/stellar";
import axios from "axios";
import StellarSdk, { Keypair, Server, TransactionBuilder } from "stellar-sdk";

const BASE_URL = process.env.VUE_APP_API_URL!;

export async function getLiquidityPools(): Promise<Pool[]> {
  return (await axios.get(BASE_URL.concat("/LiquidityPool"))).data.map(
    mapToEntity
  );
}

export async function getLiquidityPool(address: string): Promise<Pool> {
  return mapToEntity(
    (await axios.get(BASE_URL.concat("/LiquidityPool/") + address)).data
  );
}

export async function getLiquidityPoolByAssets(
  primaryAsset: Asset,
  secondaryAsset: Asset
): Promise<Pool> {
  const path = "/LiquidityPool".concat(
    `/GetByAssets/${primaryAsset.code}/${primaryAsset.issuer}/${secondaryAsset.code}/${secondaryAsset.issuer}`
  );

  return mapToEntity((await axios.get(BASE_URL + path)).data);
}

export async function getIntentXdr(intent: Intent): Promise<string> {
  let action = "";

  switch (intent.opType) {
    case "create":
      action = "CreateIntent";
      break;
    case "add":
      action = "AddIntent";
      break;
    case "remove":
      action = "RemoveIntent";
      break;
    case "swap":
      action = "SwapIntent";
      break;
    default:
      throw new Error("Unknown operation: " + intent.opType);
  }

  const cloneIntent: Intent = { ...intent };
  delete cloneIntent.privateKey;

  if (cloneIntent.isNative) {
    return getXdrFromIntent(cloneIntent);
  } else {
    const xdrResponse = await fetch(`${BASE_URL}/LiquidityPool/${action}`, {
      method: "post",
      body: JSON.stringify(cloneIntent),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    if (!xdrResponse.ok) {
      const error = await xdrResponse.json();
      throw new AppError(error.detail, error.type, error.title);
    }

    return xdrResponse.text();
  }
}

export async function submitTx(intent: Intent): Promise<boolean> {
  const xdr = await getIntentXdr(intent);
  const server = new Server(process.env.VUE_APP_HORIZON_URL!);

  const tx = TransactionBuilder.fromXDR(
    xdr,
    process.env.VUE_APP_STELLAR_NETWORK_PASSPHRASE!
  );

  tx.sign(Keypair.fromSecret(intent.privateKey!));

  try {
    await server.submitTransaction(tx);
    return true;
  } catch (e) {
    console.log(e.response.data.extras.result_xdr);
    console.log(
      StellarSdk.xdr.TransactionResult.fromXDR(
        e.response.data.extras.result_xdr,
        "base64"
      )
    );
    throw new AppError(e.detail, e.type, e.title);
  }
}
