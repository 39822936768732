import PlutoDaoApp from "./App.vue";
import "./css/index.css";
import numberFormatPlugin from "@/helpers/numberFormatPlugin";
import Native from "@/pages/Native.vue";
import TestPortal from "@/pages/TestPortal.vue";
import { store } from "@/store/store";
import BigNumber from "bignumber.js";
import Maska from "maska";
import { createApp } from "vue";
import DKToast from "vue-dk-toast";
import VueFinalModal from "vue-final-modal";
import { createRouter, createWebHashHistory } from "vue-router";

const decimalSeparator = (0.1).toLocaleString().substring(1, 2);
const groupSeparator = decimalSeparator === "." ? "," : ".";
BigNumber.config({
  DECIMAL_PLACES: 20,
  CRYPTO: true,
  FORMAT: {
    groupSize: 3,
    groupSeparator,
    decimalSeparator,
  },
});

const routes = [
  { path: "/", component: Native },
  {
    path: "/test-portal",
    component: TestPortal,
    meta: {
      title: "Test Portal - PlutoDAO",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to, from) => {
  document.title = (to.meta.title as string) || "PlutoDAO";
});

createApp(PlutoDaoApp)
  .use(numberFormatPlugin)
  .use(store)
  .use(router)
  .use(Maska)
  .use(VueFinalModal())
  .use(DKToast, {
    duration: 5000,
    positionY: "top", // 'top' or 'bottom'
    positionX: "center", // 'left', 'right' or 'center'
    styles: {
      color: "#FFF",
      backgroundColor: "#21d297",
      opacity: 1,
    },
  })
  .mount("#app");

if (!("ontouchstart" in window)) {
  const body = document.querySelector("body")!;
  body.addEventListener("mousemove", function (e: any) {
    const moveX = (e.pageX * -1) / 60;
    const moveY = (e.pageY * -1) / 60;
    const stars = document.querySelector("#stars") as HTMLElement;
    stars.style.backgroundPosition = moveX + "px " + moveY + "px";
  });
}
